import { render, staticRenderFns } from "./requirement.vue?vue&type=template&id=35c4c3fa&scoped=true&"
import script from "./requirement.vue?vue&type=script&lang=js&"
export * from "./requirement.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  
}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "35c4c3fa",
  "37927946"
  
)

export default component.exports