import Vue from "vue";
import Router from "vue-router";
import axios from 'axios';
import helper from './utils/helper'


Vue.use(Router);
// 解决报错
const originalPush = Router.prototype.push
const originalReplace = Router.prototype.replace
// push
Router.prototype.push = function push(location, onResolve, onReject) {
    // console.log(onReject + onResolve)
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => { });
}
// replace
Router.prototype.replace = function push(location, onResolve, onReject) {
    // console.log(onReject + onResolve)
    if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
    return originalReplace.call(this, location).catch(err => { })
}


//loading pages
// import LoadingPage from "./pages/LoadingPage.vue";

import LSMHome from "./pages/lsmHome.vue";
import Sign from "./pages/sign.vue";
//chat
import TimChat from "./pages/chat/tim_chat.vue";
import ClientChat from "./pages/chat/index.vue";
// import unverifiedClientChat from "./pages/chat/unVerified.vue";
//lead
// import LeadSteps from "./pages/lead/steps.vue"
//free mode
// import FreeLoading from "./pages/free_mode/loading.vue"
// import FreeIndex from "./pages/free_mode/index.vue"
// import MatchPreview from "./pages/free_mode/match_preview.vue"
// import Verification from "./pages/free_mode/verification.vue"
import Matchmaking from "./pages/matchmaking/matchmaking.vue"
import MatchmakingCallBack from "./pages/matchmaking/matchmakingCallBack.vue"
import LSMNewMatches from "./pages/matchmaking/newMatches.vue"
import LSMMatchList from "./pages/matchmaking/matchList.vue"
import OPMatchList from "./pages/matchmaking/matches_op.vue"


//onboarding
// import OnboardingSign from "./pages/onboarding/sign.vue";
import VerifiyPending from "./pages/onboarding/verifiyPending.vue";
import AccountPaused from "./pages/onboarding/accountPaused.vue"
import OnboardingSms from "./pages/onboarding/smsVerify.vue";
import OPFreeOnboarding from "./pages/onboarding/op_free_onboarding.vue";
// import OnboardingProfileSteps from "./pages/onboarding/profile-steps.vue";
//register
import RegSteps4 from "./pages/register/steps4.vue";
// import RegisterPhoto from "./pages/register/photo.vue";
// import RegisterPhoto6 from "./pages/register/photo6.vue";
// import RegisterProfile2 from "./pages/register/profile2.vue";
// import RegisterProfile from "./pages/register/profile.vue";
// import RegisterGetVerified from "./pages/register/getVerified.vue";
import RegisterWelcome from "./pages/register/welcome.vue";
// import RegisterQuestionnaire from "./pages/register/questionnaire.vue";
// import RegisterMatchPreview from "./pages/register/matchPreview.vue";
// import RegisterMatchmakers from "./pages/register/matchmakers.vue";
// import RegisterhowItWorks from "./pages/register/howItWorks.vue";
// import RegisterCheckout from "./pages/register/checkout.vue";
// import CreditCard from "./pages/profile/creditCard.vue";
//profile
import MePage from "./pages/profile/me.vue";
import Settings from "./pages/profile/settings.vue";
import PhotoEdit from "./pages/profile/photoEdit.vue";
import EditProfile from "./pages/profile/edit.vue";
// import Edit2Profile from "./pages/profile/edit2.vue";
import ViewProfile from "./pages/profile/view.vue";
import Requirement from "./pages/profile/requirement.vue";
import UserUnverified from './pages/profile/unverified.vue'
import ProfilePaused from './pages/profile/paused.vue'
import VerificationStarts from './pages/yoti/verificationStarts.vue'
import Howitwork from './pages/yoti/howitwork.vue'
import VerifyResult from './pages/yoti/verifyResult.vue'
// import MyAccount from "./pages/profile/myAccount.vue";
// import CreditCardList from "./pages/profile/creditCardList.vue";
//payment

import Checkout from "./pages/payment/checkout.vue";
import PaymentDeclined from "./pages/payment/paymentDeclined.vue";
import PaymentReceipt from "./pages/payment/paymentReceiptUpload.vue";
import PaymentSuccess from "./pages/payment/paySuccess.vue";
// import MembershipChoose from "./pages/payment/membershipChoose.vue"
// import MembershipUpgrade from "./pages/payment/membershipUpgrade.vue"
import UpgradeCongrats from "./pages/payment/upgradeCongrats.vue"
import UpgradeBook from "./pages/payment/upgradeBook.vue"
// import SpeedDatingMembership from "./pages/payment/speedDatingMembership.vue"
// import ContextualUpgrades from "./pages/payment/contextualUpgrades.vue"
import FreeDiscountUpgrade from "./pages/payment/freeDiscountUpgrade.vue"
import RenewPayment from "./pages/payment/renew.vue"
import PendingReview from "./pages/payment/pendingReview.vue"
// import PlansChoose from "./pages/payment/plansChoose.vue";

// import EventTicketSelect from "./pages/payment/eventTicketSelect.vue";
// import DateCreditSelect from "./pages/payment/dateCreditSelect.vue";
// import ExtendCreditSelect from "./pages/payment/extendCreditSelect.vue";
// import LovestruckCreditSelect from "./pages/payment/lovestruckCreditSelect.vue";
// import PayMethods from "./pages/payment/payMethods.vue";
// import payFinish from "./pages/payment/payFinish.vue";
import payFailed from "./pages/payment/payFailed.vue";
import upgradePopup from "./pages/payment/upgradePopup.vue";
//order
// import orderPaymentView from './pages/order/paymentView'
//event
// import DateEventsList from "./pages/event/dateEvents.vue"
// import EventList from "./pages/event/list.vue"
// import EventInfo from "./pages/event/info.vue"
//video
// import VideoChatRoom2 from './pages/video/chatRoom.vue'
// import VideoChatRoom from './pages/video/chatRoomTencent.vue'
//chat
// import ChatRoom from "./pages/chat-room.vue";
import Unauthorized from "./pages/unauthorized.vue";

//search
// import searchMatchList from './pages/search/matchList.vue'
// //match
// import introMatch from './pages/match/introMatches.vue'
// import LikePage from './pages/match/likes.vue'
// import newMatch from './pages/match/newMatch.vue'
// import newMatches from './pages/match/newMatches.vue'
// import newMatch2 from './pages/match/newMatch2.vue'
// import MatchList from './pages/match/matchList.vue'

// import MatchClient from './pages/match/matchClient.vue'
// import MatchCard from './pages/match/matchCard.vue'
// import matchList2d from './pages/match/matchList2d.vue'
//elites
// import EliteList from './pages/elites/eliteList.vue'
import BookConsultation from './pages/matchmaking/bookConsultation.vue'
//dates
// import DateEventList from './pages/dates/dateEventList.vue'
// import DateEventInfo from './pages/dates/dateEventInfo.vue'
// import DatesList from './pages/dates/datesList.vue'
// import DateInfo from './pages/dates/dateInfo.vue'
// import ViewFeedback from './pages/dates/viewFeedback.vue'
// import GiveFeedback from './pages/dates/giveFeedback.vue'
//single page
import SinglePage from './pages/others/singlePage.vue'
//err
import WebError from "./pages/webError.vue"
//test
import Test1 from "./pages/test/local.vue"
import Canvas from "./pages/test/canvas.vue"
import Touch from "./pages/test/touch.vue"
import TestJsHander from "./pages/test/JsHandler.vue"
import Copper from "./pages/test/copper.vue"
import Gps from "./pages/test/gps.vue"
import Yoti from "./pages/test/yoti.vue"
import TestYotiResult from "./pages/test/yotiResult.vue"
import ServerNotify from "./pages/test/serverNotify.vue"
// import testUpload from "./pages/test/upload.vue"
//import Test2 from "./pages/test/Test2.vue"
import jwt_decode from 'jwt-decode';
import ApiRequest from './api/apiRequest'
// import { nesting } from "postcss-selector-parser";
let appPublicPath = 'app'
let pathFix = ''
let jwt = {
    decode: (token) => {
        return jwt_decode(token)
    }
}
var defautlCity = 'hong-kong';

let $http = axios.create({
    baseURL: "https://api.lovestruck.com",
})

export function createRouter(store, i18n) {

    const routerNames = {
        // EMPLOYEE_CHAT: 'echat-room',
        // CLIENT_CHAT: 'client-chat-room',
        WEB_ERROR: 'WebError',
        UNAUTHORIZED: 'Unauthorized'
    }

    let router = new Router({
        mode: "history",
        base: process.env.BASE_URL,
        scrollBehavior(to, from, savedPosition) {
            console.log('scrollBehavior', savedPosition)
            if (savedPosition) {
                return savedPosition;
            } else {
                return { x: 0, y: 0 }
            }
        },
        routes: [
            //
            {
                path: `${pathFix}/`,
                name: "home-lsm",
                component: LSMHome,
                meta: {
                    auth: false
                }
            },
            // {
            //     path: "/:city/loading",
            //     name: "loading-page",
            //     // component: LoadingPage,
            //     component: HomePageRed,
            //     meta: {
            //         auth: false
            //     }
            // },

            // {
            //     path: "/:city/home-v5",
            //     name: "home-v5",
            //     component: HomePageV5,
            //     meta: {
            //         auth: false
            //     }
            // },
            // {
            //     path: "/:city/red-home",
            //     name: "home-red",
            //     component: HomePageRed,
            //     meta: {
            //         auth: false
            //     }
            // },
            // {
            //     path: "/:city/home-v4",
            //     name: "home-v4",
            //     component: HomePage,
            //     meta: {
            //         auth: false
            //     }
            // },
            {
                path: `${pathFix}/test/touch`,
                name: "test-touch",
                component: Touch,
                meta: {
                    auth: false
                }
            },
            {
                path: `${pathFix}/test/local`,
                name: "test-test1",
                component: Test1,
                meta: {
                    auth: false
                }
            },
            {
                path: `${pathFix}/test/canvas`,
                name: "test-canvas",
                component: Canvas,
                meta: {
                    auth: false
                }
            },
            {
                path: `${pathFix}/test/jsHander`,
                name: "test-test2",
                component: TestJsHander,
                meta: {
                    auth: false
                }
            },
            {
                path: `${pathFix}/test/copper`,
                name: "test-copper",
                component: Copper,
                meta: {
                    auth: false
                }
            },
            {
                path: `${pathFix}/test/yoti`,
                name: "test-yoti",
                component: Yoti,
                meta: {
                    auth: false
                }
            },
            {
                path: `${pathFix}/test/yoti-result`,
                name: "test-yoti-result",
                component: TestYotiResult,
                meta: {
                    auth: false
                }
            },
            {
                path: `${pathFix}/test/server-notify`,
                name: "test-server-notify",
                component: ServerNotify,
                meta: {
                    auth: false
                }
            },
            {
                path: `${pathFix}/test/gps`,
                name: "test-gps",
                component: Gps,
                meta: {
                    auth: false
                }
            },
            // 
            {
                path: `${pathFix}/welcome`,
                name: "ls-welcomes",
                component: RegisterWelcome,
                meta: {
                    auth: true
                }
            },
            {
                path: `${pathFix}/reg-step`,
                name: "reg-step",
                component: RegSteps4,
                meta: {
                    auth: true
                }
            },
            {
                path: `${pathFix}/profile/edit/:name`,
                name: "profile-edit-name",
                component: RegSteps4,
                meta: {
                    auth: true
                }
            },
            // {
            //     path: "/:city/regStep4",
            //     name: "reg-step-ss",
            //     component: RegSteps4,
            //     meta: {
            //         auth: true
            //     }
            // },
            // {
            //     path: "/:city/about",
            //     name: "about-us",
            //     component: OthersAboutUs,
            //     meta: {
            //         auth: false
            //     }
            // },
            // {
            //     path: "/:city/team",
            //     name: "our-team",
            //     component: OthersTeam,
            //     meta: {
            //         auth: false
            //     }
            // },
            // {
            //     path: "/:city/contact",
            //     name: "contact-us",
            //     component: OthersContact,
            //     meta: {
            //         auth: false
            //     }
            // },
            // {
            //     path: "/:city/success-stories",
            //     name: "success-stories",
            //     component: OthersSuccessStories,
            //     meta: {
            //         auth: false
            //     }
            // },
            {
                path: `${pathFix}/:city/terms`,
                name: "terms",
                component: SinglePage,
                meta: {
                    auth: false
                }
            },
            {
                path: `${pathFix}/:city/cookies`,
                name: "cookies",
                component: SinglePage,
                meta: {
                    auth: false
                }
            },
            {
                path: `${pathFix}/:city/privacy`,
                name: "privacy-policy",
                component: SinglePage,
                meta: {
                    auth: false
                }
            },
            {
                path: `${pathFix}/:city/privacy-policy`,
                name: "privacy-policy2",
                component: SinglePage,
                meta: {
                    auth: false
                }
            },
            // {
            //     path: "/:city/lead-steps",
            //     name: "lead-steps",
            //     component: LeadSteps,
            //     meta: {
            //         auth: false
            //     }
            // },
            // {
            //     path: "/:city/register/photo",
            //     name: "register-photo",
            //     component: RegisterPhoto6,
            //     meta: {
            //         auth: true
            //     }
            // },
            {
                path: `${pathFix}/matchmaking`,
                name: "matchmaking-index",
                // component: MatchmakingCallBack,
                component: Matchmaking,
                meta: {
                    auth: true
                }
            },
            {
                path: `${pathFix}/matchmaking-new-matches`,
                name: "matchmaking-new-matches",
                component: LSMNewMatches,
                meta: {
                    auth: true
                }
            },
            {
                path: `${pathFix}/matchmaking-matches`,
                name: "matchmaking-match-list",
                component: LSMMatchList,
                meta: {
                    auth: true
                }
            },
            {
                path: `${pathFix}/op-matches`,
                name: "op-match-list",
                component: OPMatchList,
                meta: {
                    auth: true
                }
            },
            {
                path: `${pathFix}/matchmaking-callback`,
                name: "matchmaking-callback",
                component: MatchmakingCallBack,
                meta: {
                    auth: true
                }
            },
            // {
            //     path: "/:city/free-home",
            //     name: "free-index",
            //     component: FreeIndex,
            //     meta: {
            //         auth: true
            //     }
            // },
            // {
            //     path: "/:city/free-loading",
            //     name: "free-loading",
            //     component: FreeLoading,
            //     meta: {
            //         auth: true
            //     }
            // },
            // {
            //     path: "/:city/match-preview",
            //     name: "match-preview",
            //     component: MatchPreview,
            //     meta: {
            //         auth: true
            //     }
            // },
            // {
            //     path: "/:city/verification-process",
            //     name: "client-verification",
            //     component: Verification,
            //     meta: {
            //         auth: true
            //     }
            // },

            {
                path: `${pathFix}/chat`,
                name: "client-chat",
                component: TimChat,
                meta: {
                    auth: true
                }
            },
            {
                path: `${pathFix}/web-chat`,
                // redirect: '/:city/tim-chat',
                name: "client-web-chat",
                component: ClientChat,
                meta: {
                    auth: true
                }
            },
            {
                path: `${pathFix}/chat/matchmaker`,
                // redirect: '/:city/tim-chat',
                name: "matchmaker-chat",
                component: TimChat,
                // component: ClientChat,
                meta: {
                    auth: true
                }
            },
            {
                path: `${pathFix}/profile/unverified`,
                name: "profile-unverified",
                component: UserUnverified,
                meta: {
                    auth: true
                }
            },
            {
                path: `${pathFix}/profile/paused`,
                name: "profile-paused",
                component: ProfilePaused,
                meta: {
                    auth: true
                }
            },

            {
                path: `${pathFix}/profile/setting`,
                name: "profile-setting",
                component: Settings,
                meta: {
                    auth: true
                }
            },
            // {
            //     path: `${pathFix}/profile2/edit`,
            //     name: "profile2-edit",
            //     component: Edit2Profile,
            //     meta: {
            //         auth: false
            //     }
            // },
            {
                path: `${pathFix}/profile/photo`,
                name: "photo-edit",
                component: PhotoEdit,
                meta: {
                    auth: true
                }
            },
            {
                path: `${pathFix}/profile/edit`,
                name: "profile-edit",
                component: EditProfile,//EditProfile,
                meta: {
                    auth: true
                }
            },
            {
                path: `${pathFix}/profile/requirement`,
                name: "profile-requirement",
                component: Requirement,
                meta: {
                    auth: true
                }
            },
            {
                path: `${pathFix}/profile/me`,
                name: "profile-me",
                component: MePage,
                meta: {
                    auth: true
                }
            },
            {
                path: `${pathFix}/profile/verify-starts`,
                name: "verify-starts",
                component: VerificationStarts,
                meta: {
                    auth: true
                }
            },
            {
                path: `${pathFix}/profile/verify-howitwork`,
                name: "verify-howitwork",
                component: Howitwork,
                meta: {
                    auth: true
                }
            },
            {
                path: `${pathFix}/profile/verify-result`,
                name: "verify-result",
                component: VerifyResult,
                meta: {
                    auth: true
                }
            },
            {
                path: `${pathFix}/profile/verify-error`,
                name: "verify-error",
                component: VerifyResult,
                meta: {
                    auth: true
                }
            },
            // {
            //     path: "/:city/profile/callTime",
            //     name: "profile-call-time",
            //     component: RegisterGetVerified,
            //     meta: {
            //         auth: true
            //     }
            // },
            // {
            //     path: `${pathFix}/profile/photo6`,
            //     name: "profile-photo",
            //     component: RegisterPhoto6,
            //     meta: {
            //         auth: true
            //     }
            // },

            {
                path: `${pathFix}/profile/:clientId`,
                name: "profile-view",
                component: ViewProfile,
                meta: {
                    auth: true
                }
            },
            // {
            //     path: "/:city/likes",
            //     name: "like-list",
            //     component: LikePage,
            //     meta: {
            //         auth: true
            //     }
            // },
            // {
            //     path: "/:city/matches",
            //     name: "match-new",
            //     component: newMatches,
            //     // component: newMatch,
            //     meta: {
            //         auth: true
            //     }
            // },
            // {
            //     path: "/:city/new-matches",
            //     name: "match-new2",
            //     component: newMatches,
            //     meta: {
            //         auth: true
            //     }
            // },
            // {
            //     path: "/:city/intro-matches",
            //     name: "match-intro",
            //     component: introMatch,
            //     meta: {
            //         auth: true
            //     }
            // },
            // {
            //     path: "/:city/match/list",
            //     name: "match-list",
            //     component: MatchList,
            //     meta: {
            //         auth: true
            //     }
            // },
            // {
            //     path: "/:city/match/list/:matchType",
            //     name: "match-matchType",
            //     component: MatchList,
            //     meta: {
            //         auth: true
            //     }
            // },
            // {
            //     path: "/:city/match/clients",
            //     name: "match-client",
            //     component: MatchClient,
            //     meta: {
            //         auth: false
            //     }
            // },
            // {
            //     path: `${pathFix}/elite/list`,
            //     name: "elite-list",
            //     component: EliteList,
            //     meta: {
            //         auth: false
            //     }
            // },
            {
                path: `${pathFix}/book_consultation`,
                name: "book-consultation",
                component: BookConsultation,
                meta: {
                    auth: true
                }
            },
            // {
            //     path: "/:city/date/list",
            //     name: "dates-list",
            //     component: DatesList,
            //     meta: {
            //         auth: true
            //     }
            // },
            // {
            //     path: "/:city/date/1-on-1",
            //     name: "date-1on1",
            //     component: DateEventList,
            //     meta: {
            //         auth: true
            //     }
            // },
            // {
            //     path: "/:city/date/events",
            //     name: "date-event-list",
            //     component: DateEventList,
            //     meta: {
            //         auth: true
            //     }
            // },
            // {
            //     path: "/:city/date/past",
            //     name: "date-past",
            //     component: DateEventList,
            //     meta: {
            //         auth: true
            //     }
            // },
            // {
            //     path: "/:city/date/event/:id",
            //     name: "date-event-info",
            //     component: DateEventInfo,
            //     meta: {
            //         auth: true
            //     }
            // },
            // {
            //     path: "/:city/date/:id",
            //     name: "dates-info",
            //     component: DateInfo,
            //     meta: {
            //         auth: true
            //     }
            // },
            // {
            //     path: "/:city/date/view-feedback/:id/:tag",
            //     name: "dates-view-feedback",
            //     component: ViewFeedback,
            //     meta: {
            //         auth: true
            //     }
            // },
            // {
            //     path: "/:city/date/give-feedback/:id",
            //     name: "dates-give-feedback",
            //     component: GiveFeedback,
            //     meta: {
            //         auth: true
            //     }
            // },

            // {
            //     path: "/:city/video/room2/:consultation_id",
            //     name: "video-room2",
            //     component: VideoChatRoom2,
            //     meta: {
            //         auth: false
            //     }
            // },
            // {
            //   path: "/:city/video/room",
            //   name: "video-room",
            //   component: VideoChatRoom,
            //   meta: {
            //     auth: false
            //   }
            // },
            {
                path: `${pathFix}/payment/upgrade`,
                name: "contextual-upgrades",
                // component: PlansChoose,
                // component: ContextualUpgrades,
                component: UpgradeBook,
                meta: {
                    auth: true
                }
            },
            {
                path: `${pathFix}/payment/pending-review`,
                name: "pending-review",
                // component: PlansChoose,
                component: PendingReview,
                meta: {
                    auth: true
                }
            },

            // {
            //     path: "/:city/payment/methods",
            //     name: "payment-methods",
            //     // component: PayMethods,
            //     component: Checkout,
            //     meta: {
            //         auth: false
            //     }
            // },
            // {
            //     path: "/:city/payment/membership-choose",
            //     name: "membership-choose",
            //     component: MembershipChoose,
            //     meta: {
            //         auth: false
            //     }
            // },
            // {
            //     path: "/:city/payment/membership-upgrade",
            //     name: "membership-upgrade",
            //     component: MembershipUpgrade,
            //     meta: {
            //         auth: false
            //     }
            // },
            // {
            //     path: "/:city/payment/methodsold",
            //     name: "payment-methodsold",
            //     component: PayMethods,
            //     meta: {
            //         auth: false
            //     }
            // },
            // {
            //     path: "/:city/payment/finish/:planName",
            //     name: "payment-finish",
            //     component: payFinish,
            //     meta: {
            //         auth: false
            //     }
            // },
            {
                path: `${pathFix}/payment/failed`,
                name: "payment-failed",
                component: payFailed,
                meta: {
                    auth: false
                }
            },
            {
                path: `${pathFix}/payment/upgradePopup`,
                name: "payment-upgrade-pop",
                component: upgradePopup,
                meta: {
                    auth: true
                }
            },

            {
                path: `${pathFix}/payment/checkout`,
                name: "payment-checkout",
                component: Checkout,
                meta: {
                    auth: false
                }
            },
            {
                path: `${pathFix}/payment/declined`,
                name: "payment-declined",
                component: PaymentDeclined,
                meta: {
                    auth: false
                }
            },
            {
                path: `${pathFix}/payment/receipt-upload`,
                name: "payment-receipt",
                component: PaymentReceipt,
                meta: {
                    auth: true
                }
            },
            {
                path: `${pathFix}/payment/success`,
                name: "payment-success",
                component: PaymentSuccess,
                meta: {
                    auth: false
                }
            },
            {
                path: `${pathFix}/payment/upgrade-congrats`,
                name: "upgrade-congrats",
                component: UpgradeCongrats,
                meta: {
                    auth: true
                }
            },
            ///app/payment/success-[city]-[package-name]
            {
                path: `${pathFix}/payment/success-:scity-:pkname-:month`,
                name: "upgrade-congrats-city",
                component: UpgradeCongrats,
                meta: {
                    auth: true
                }
            },
            {
                path: `${pathFix}/payment/renew`,
                name: "renew-payment",
                component: RenewPayment,
                meta: {
                    auth: true
                }
            },
            {
                path: `${pathFix}/payment/plus`,
                name: "upgrade-regular",
                component: FreeDiscountUpgrade,
                meta: {
                    auth: true
                }
            },
            {
                path: `${pathFix}/payment/starter`,
                name: "upgrade-starter",
                component: FreeDiscountUpgrade,
                meta: {
                    auth: true
                }
            },

            // {
            //     path: "/:city/payment/date",
            //     redirect: `/${appPublicPath}/:city/payment/dates`
            //     // name: "payment-date",
            //     // component: DateCreditSelect,
            //     // meta: {
            //     //     auth: false
            //     // }
            // },

            // {
            // 	path: "/:city/employee/chat",
            // 	name: "echat-room",
            // 	component: ChatRoom,
            // 	meta: {
            // 		auth: true
            // 	}
            // },
            // {
            // 	path: "/:city/employee/clientChat",
            // 	name: "client-chat-room",
            // 	component: ClientChatRoom,
            // 	meta: {
            // 		auth: true
            // 	}
            // },

            //
            // {
            //     path: "/:city/event/list",
            //     name: 'event-list',
            //     component: EventList,
            //     meta: {
            //         auth: false
            //     }
            // },
            // {
            //     path: "/:city/events",
            //     name: 'new-event-list',
            //     component: DateEventsList,
            //     meta: {
            //         auth: false
            //     }
            // },
            // {
            //     path: "/:city/event/:id",
            //     name: 'event-info',
            //     component: EventInfo,
            //     meta: {
            //         auth: false
            //     }
            // },
            {
                path: `${pathFix}/:city/signin`,
                name: 'sign-in',
                // component: OnboardingSign,
                component: Sign,
                meta: {
                    auth: false
                }
            },
            {
                path: `${pathFix}/signin`,
                name: 'sign-in-2',
                // component: OnboardingSign,
                component: Sign,
                meta: {
                    auth: false
                }
            },
            {
                path: `${pathFix}/verify-pending`,
                name: 'verify-pending',
                component: VerifiyPending,
                meta: {
                    auth: false
                }
            },
            {
                path: `${pathFix}/account-paused`,
                name: 'account-paused',
                component: AccountPaused,
                meta: {
                    auth: false
                }
            },
            {
                path: `${pathFix}/smsverify`,
                name: 'sms-verify',
                component: OnboardingSms,
                meta: {
                    auth: false
                }
            },
            {
                path: `${pathFix}/smsverify-leadform`,
                name: 'sms-verify-leadform',
                component: OnboardingSms,
                meta: {
                    auth: false
                }
            },
            {
                path: `${pathFix}/screening/:step`,
                name: "onboarding-op-free",
                component: OPFreeOnboarding,
                meta: {
                    auth: true
                }
            },
            // {
            //     path: "/:city/profile-steps",
            //     name: 'profile-steps',
            //     component: OnboardingProfileSteps,
            //     meta: {
            //         auth: true
            //     }
            // },
            {
                path: `${pathFix}/signout`,
                name: 'sign-out',
                // component: F7app,
                // meta: {
                // 	auth: false
                // }
                beforeEnter: (to, from, next) => {

                    Vue.prototype.$storage.clearLogin();
                    // console.log('goto home 770', ncity)
                    // return false;
                    let paramCity = Vue.prototype.$storage.get('ls_city')
                    if (!paramCity) {
                        paramCity = defautlCity
                    }

                    if (typeof location == 'undefined') {
                        next({
                            name: "city-home",
                            params: { city: paramCity }
                        })
                    } else {
                        location.href = `${location.origin}/${appPublicPath}/${paramCity}`
                        // location.href = `${location.origin}/${appPublicPath}/${_.get(to.params, 'city', 'hongkong')}`
                    }


                    return false;
                }
            },
            {
                path: `${pathFix}/error`,
                name: "WebError",
                component: WebError
            },
            {
                path: `${pathFix}/unauthorized`,
                name: "Unauthorized",
                component: Unauthorized
            },
            {
                path: `${pathFix}/:city`,
                name: "city-home",
                component: LSMHome,
                meta: {
                    auth: false
                },
                // async: function (to, routeFrom, resolve) {
                //     (async () => {
                //         console.log('city home', to, routeFrom, resolve)
                //     })()
                // }
            },

        ]
    });

    router.beforeEach(async (to, from, next) => {
        console.log("route init to->from", { name: to.name, fullPath: to.fullPath }, { name: from.name, fullPath: from.fullPath });
        switch (to.name) {
            // case 'city-home':
            //     if (!from.name) {
            //         if (to.params.city == 'hongkong') {
            //             next({
            //                 name: "city-home",
            //                 params: { city: 'hong-kong' }
            //             });
            //         } else {
            //             next();
            //         }
            //     } else {
            //         next();
            //     }

            //     break;
            case routerNames.WEB_ERROR:
            case routerNames.UNAUTHORIZED:
                {

                    next();
                    break;
                }
            default:
                {
                    //sign-in, city-home
                    var paramCity = '';
                    var tokenLogined = false;
                    if (to.name == 'city-home' || to.name == 'sign-in') {
                        paramCity = to.params.city
                        Vue.prototype.$storage.set('ls_city', to.params.city)
                    } else {
                        paramCity = Vue.prototype.$storage.get('ls_city')
                        if (to.query.ls_city && paramCity != to.query.ls_city) {
                            Vue.prototype.$storage.set('ls_city', to.query.ls_city)
                            paramCity = to.query.ls_city

                            delete to.query.ls_city
                            router.replace({ name: to.name, params: to.params, query: to.query })

                            return false;

                        }
                    }
                    //   console.log('--- router.beforeEach')

                    // console.log('paramCity1', paramCity, to.name)


                    let store_token = Vue.prototype.$storage.get('token')
                    let cur_site = {}
                    let foundCity = false

                    if (!paramCity) {
                        paramCity = defautlCity
                    }

                    // console.log('paramCity2', paramCity)

                    // console.log('store_token=', store_token)
                    // Vue.prototype.$storage.set('localTest', '2020.05.18')
                    function frontEndCheck(to) {
                        let userInfo = Vue.prototype.$storage.get("userInfo")
                        let user = Vue.prototype.$storage.get("user")
                        // let token = Vue.prototype.$storage.get("token")
                        // console.log('user===', user)
                        // console.log(userInfo, to.name, _.includes(['loading-page', 'city-home'], to.name))
                        // console.log('..frontEndCheck', to.name, store_token, userInfo)
                        //, 'profile-me'
                        //_.includes(['loading-page', 'city-home', 'home-lsm'], to.name) &&
                        // if (_.get(userInfo, 'access_token', false)) {
                        if (_.includes(['loading-page', 'city-home', 'home-lsm'], to.name) && store_token && userInfo) {

                            //window.location.href = _.get(userInfo, 'crm_link');
                            // console.log('need go to crm link....')

                            // let ncity = _.get(to.params, 'city', defautlCity)
                            console.log('goto home 842--', paramCity)
                            // return false;
                            let jump = helper.parseFirstLoadPage(userInfo)
                            // console.log('jump', jump);
                            if (jump.type == 'link') {

                                console.log(jump.route_name)
                                if (typeof location != 'undefined') {
                                    location.href = jump.route_name

                                }

                            } else {
                                console.log('goto route 842-2', jump.route_name)
                                next({
                                    name: jump.route_name,
                                    params: { city: paramCity, ...jump.params },
                                    query: jump.query ? jump.query : {}
                                })
                            }





                            return true;
                        }
                        if (to.query.token) {
                            delete to.query.token
                            router.replace({ name: to.name, params: to.params, query: to.query })
                            return true;
                        }

                        if (to.matched.some(record => record.meta.auth)) {

                            let hasAuth = Vue.prototype.$storage.getAuthTag();


                            // console.log('----- need auth', hasAuth, user)

                            if (!user || _.get(user, 'isEmployee')) {
                                // console.log('----- Unauthorized .....')
                                Vue.prototype.$storage.clearLogin();
                                // Vue.prototype.$storage.remove('user')
                                // Vue.prototype.$storage.remove('userInfo')
                                // Vue.prototype.$storage.remove('token')

                                // let ncity = _.get(to.params, 'city', defautlCity)
                                console.log('goto home 875', paramCity)
                                // return false;

                                if (typeof location == 'undefined') {
                                    next({
                                        name: "city-home",
                                        params: { city: paramCity }
                                    })
                                } else {
                                    //2023
                                    // console.log('local 1')
                                    location.href = `${location.origin}/${appPublicPath}/${paramCity}`
                                    // location.href = `${location.origin}/${appPublicPath}/${ncity}`
                                }
                                return false;
                            } else if (hasAuth) {
                                return true;
                            } else {
                                ApiRequest.getIsAuth('', { token: _.get(to.query, 'token', _.get(user, 'token', false)) }, store.state)
                                    .then(rs => {
                                        // console.log('======getIsAuth', rs)
                                        if (!rs.isAuth) {
                                            Vue.prototype.$storage.clearLogin();

                                            // let ncity = _.get(to.params, 'city', defautlCity)
                                            console.log('goto home 896', paramCity)
                                            // return false;

                                            if (typeof location == 'undefined') {
                                                next({
                                                    name: "city-home",
                                                    params: { city: paramCity }
                                                })
                                            } else {
                                                //2023
                                                // console.log('local 2')
                                                location.href = `${location.origin}/${appPublicPath}/${paramCity}`
                                                // location.href = `${location.origin}/${appPublicPath}/${ncity}`
                                            }
                                            // next({
                                            //     // name: "Unauthorized"
                                            //     name: "city-home",
                                            //     params: { city: ncity }
                                            // });
                                            return false;
                                        } else {

                                            Vue.prototype.$storage.setAuthTag()


                                            return true;
                                        }
                                    })
                                    .catch(err => {
                                        // console.log('check auth error', err)
                                        Vue.prototype.$storage.clearLogin();
                                        // Vue.prototype.$storage.remove('user')
                                        // Vue.prototype.$storage.remove('userInfo')
                                        // Vue.prototype.$storage.remove('token')

                                        // let ncity = _.get(to.params, 'city', defautlCity)
                                        console.log('goto home 928', paramCity)
                                        // return false;

                                        if (typeof location == 'undefined') {
                                            next({
                                                name: "city-home",
                                                params: { city: paramCity }
                                            })
                                        } else {
                                            // console.log('local 3')
                                            location.href = `${location.origin}/${appPublicPath}/${paramCity}`
                                            // location.href = `${location.origin}/${appPublicPath}/${ncity}`
                                        }
                                        // next({
                                        //     // name: "Unauthorized"
                                        //     name: "city-home",
                                        //     params: { city: ncity }
                                        // });
                                        return false;
                                    })

                            }

                        }

                        return true;
                    }

                    let userOrTokenCheck = new Promise((resolve, reject) => {
                        //check in front-end
                        if (typeof window != 'undefined') {

                            if ((_.includes(['loading-page', 'city-home', 'home-lsm'], to.name) && !to.query.token && store_token) || (to.query.token && to.query.token != store_token)) {
                                // Vue.prototype.$layer.loading();
                                let checkToken = to.query.token ? to.query.token : store_token;
                                let payload = jwt.decode(checkToken);

                                let now = Math.round((new Date()).getTime() / 1000);
                                // console.log('payload=====', payload, now);
                                if (!_.get(payload, 'clientId', false) || now > _.get(payload, 'exp', 0)) {
                                    // console.log('frontEndCheck ---- to');
                                    if (frontEndCheck(to)) {
                                        resolve();
                                    } else {
                                        reject('frontEndCheck faied at token')
                                    }
                                }
                                ApiRequest.postLoginByToken(checkToken, store.state)
                                    .then(rs => {
                                        // console.log('----------getLoginByToken', rs)

                                        // return false;
                                        // process.exit;
                                        helper.setLoginData(rs)
                                        // Vue.prototype.$storage.set('user', {
                                        //     id: _.get(rs, 'client_id', ''),
                                        //     userId: _.get(rs, 'client_id', ''),
                                        //     token: _.get(rs, 'access_token', ''),
                                        //     isEmployee: false,
                                        //     isCrm: true
                                        // })
                                        // Vue.prototype.$storage.set('userInfo', rs)
                                        // Vue.prototype.$storage.set('token', _.get(rs, 'access_token', ''))
                                        // Vue.prototype.$storage.setAuthTag();
                                        store.commit('SET_TOKEN', _.get(rs, 'access_token', ''))
                                        store.commit('SET_CUR_LANG', { curlang: _.get(rs, 'lang_code', '') })
                                        tokenLogined = true
                                        if (frontEndCheck(to)) {
                                            resolve();
                                        } else {
                                            reject('frontEndCheck faied at token login')
                                        }
                                    })
                                    .catch(err => {
                                        // console.log('--------getLoginByToken err', err)
                                        //next({name:'WebError'})
                                        //return;
                                        Vue.prototype.$storage.remove('user');
                                        Vue.prototype.$storage.remove('userInfo');
                                        Vue.prototype.$storage.remove('token');

                                        if (frontEndCheck(to)) {
                                            // console.log('--catch--frontEndCheck')
                                            resolve();
                                        } else {
                                            reject('frontEndCheck faied at token login error')
                                        }
                                    })
                            } else {
                                // console.log('--no-token')
                                if (frontEndCheck(to)) {
                                    console.log('--no-token--resolve')
                                    resolve();
                                } else {
                                    reject('frontEndCheck faied at no-token')
                                }
                            }
                        } else {
                            // console.log('--no-window--resolve')
                            resolve();
                        }

                    });



                    userOrTokenCheck.then(() => {
                        console.log('--FETCH_SITE_DATA--2020')
                        return store.dispatch('FETCH_SITE_DATA')
                    })
                        .then((storeRes) => {
                            let cities = store.getters.cities
                            // console.log('cities', cities, storeRes)
                            foundCity = _.get(cities, paramCity, false)
                            if (!foundCity) {
                                paramCity = defautlCity
                                foundCity = _.get(cities, defautlCity, false)
                            }

                            // console.log('---store.dispatch-----FETCH_SITE_DATA', paramCity, _.get(to, 'params.city'), foundCity, to.name)
                            // return false;
                            if (to.name == 'home-lsm' || !foundCity) {
                                console.log('--- FETCH_IP_CITY begin')
                                return store.dispatch('FETCH_IP_CITY')
                            } else if (to.name == 'city-home' && !foundCity) {
                                console.log('--- FETCH_IP_CITY 2')
                                return store.dispatch('FETCH_IP_CITY')
                            } else {
                                // console.log('--- has city', to.params.city)
                                let sites = store.getters.sites
                                let cities = store.getters.cities
                                let url_city = paramCity//to.params.city;
                                //let store_city = store.getters.curCity;

                                if (url_city && cities[url_city]) {
                                    cur_site = _.get(sites, cities[url_city]['index'], false)

                                    if (!cur_site) {
                                        // console.log('not found site by url city', url_city)
                                        next({ name: 'WebError' })
                                        return;
                                    }

                                    store.commit('SET_CUR_CITY', { curcity: url_city })
                                    store.commit('SET_CUR_SITE', { cursite: cur_site })
                                    // console.log('===url_city', url_city, '====', cur_site)
                                    return Promise.resolve({ "success": true });
                                } else {
                                    //go to default city
                                    // console.log('go to default city 000');
                                    console.log('goto home 1069', defautlCity)
                                    // return false;

                                    // next({ name: 'city-home', params: { city: defautlCity } })
                                    if (typeof location == 'undefined') {
                                        router.push({ path: `/${defautlCity}` }).catch(err => { })
                                        // next({
                                        //     name: "city-home",
                                        //     params: { city: defautlCity }
                                        // })
                                    } else {
                                        // console.log('local 4')
                                        location.href = `${location.origin}/${appPublicPath}/${defautlCity}`
                                        // location.href = `${location.origin}/${appPublicPath}/${defautlCity}`
                                    }
                                    return;
                                }
                            }
                        })
                        .then((rs) => {
                            // console.log('=====step 3333', rs);
                            let ipCity = store.getters.ipCity;
                            // if (to.name == 'loading-page' ||  !foundCity) {
                            if (ipCity && ipCity != 'undefined' && !foundCity) {
                                // console.log('--- FETCH_IP_CITY end')
                                // next({ name: 'city-home', params: { city: ipCity } })
                                console.log('goto home 1091', ipCity)
                                // return false;
                                if (typeof location == 'undefined') {
                                    console.log('city-home not foundCity..')
                                    // next(`/${ipCity}`)
                                    router.push({ path: `/${ipCity}` }).catch(err => { })
                                    // next({
                                    //     name: "city-home",
                                    //     params: { city: ipCity }
                                    // })
                                } else {

                                    // console.log('local 5')
                                    location.href = `${location.origin}/${appPublicPath}/${ipCity}`
                                }
                                return;

                            } else if ((!ipCity || ipCity == 'undefined') && !foundCity) {
                                // next({ name: 'city-home', params: { city: defautlCity } })
                                console.log('goto home 1112', defautlCity)
                                // return false;

                                if (typeof location == 'undefined') {
                                    router.push({ path: `/${defautlCity}` }).catch(err => { })
                                    // next({
                                    //     name: "city-home",
                                    //     params: { city: defautlCity }
                                    // })
                                } else {
                                    // console.log('local 6')
                                    location.href = `${location.origin}/${appPublicPath}/${defautlCity}`
                                    // location.href = `${location.origin}/${appPublicPath}/${defautlCity}`
                                }
                                return;
                            } else {
                                console.log('--- set lang')
                                let isviewed = Vue.prototype.$storage.get('isviewed')
                                let lang = cur_site.lang_default;
                                let store_lang = store.getters.curLang
                                let user_lang = _.get(Vue.prototype.$storage.get('userInfo'), 'lang_code', false);

                                if (to.query.lang && user_lang != to.query.lang) {
                                    lang = to.query.lang;
                                } else if (user_lang) {
                                    lang = user_lang;
                                } else if (store_lang && isviewed) {
                                    lang = store_lang;
                                }

                                // if (!_.find(cur_site.langs, { 'lang_code': lang })) {
                                //     lang = cur_site.lang_default;
                                // }
                                if (_.indexOf(['en_us', 'zh_cn', 'zh_hk', 'th'], lang.toLowerCase()) == -1) {
                                    lang = cur_site.lang_default;
                                }
                                // console.log('langs:', lang, cur_site.lang_default, store_lang, to.query.lang, user_lang)
                                store.commit('SET_CUR_LANG', { curlang: lang })
                                Vue.prototype.$storage.set('cur-lang', lang)
                                Vue.prototype.$storage.set('isviewed', 1)
                                console.log('--- FETCH_TRANSLATION_DATA')
                                return store.dispatch('FETCH_TRANSLATION_DATA')
                            }
                        })
                        .then(() => {
                            console.log('loaded translation')

                            let messages = store.getters.translation

                            i18n.setLocaleMessage('en_US', _.get(messages, 'en_us'))
                            i18n.setLocaleMessage('zh_CN', _.get(messages, 'zh_cn'))
                            i18n.setLocaleMessage('zh_HK', _.get(messages, 'zh_hk'))
                            i18n.setLocaleMessage('th', _.get(messages, 'th'))


                            // console.log('-----store.getters.curLang', store.getters.curLang)
                            i18n.locale = store.getters.curLang;

                            // console.log('--- next', tokenLogined)
                            // if (tokenLogined && typeof location != 'undefined') {
                            //     console.log('reload page')
                            //     location.reload()
                            // } else {
                            next();
                            // }

                        })
                        .catch(err => {
                            // if (typeof window != 'undefined') {
                            // 	Vue.prototype.$layer.closeAll('loading');
                            // }
                            console.log('error002', err)
                            // next({ name: 'WebError' })
                            Vue.prototype.$storage.clearLogin();
                            console.log('goto home 1175', defautlCity)
                            // return false;
                            // return false;
                            // next({ name: 'city-home', params: { city: defautlCity } })
                            if (typeof location == 'undefined') {
                                router.push({ path: `/${defautlCity}` }).catch(err => { })
                                // next({
                                //     name: "city-home",
                                //     params: { city: defautlCity }
                                // })
                            } else {
                                // console.log('local 7')
                                location.href = `${location.origin}/${appPublicPath}/${defautlCity}`
                                // location.href = `${location.origin}/${appPublicPath}/${defautlCity}`
                            }
                        });

                    break;
                }

        }

    });

    // manual page_view event is required, gtag auto detect viritual page 
    // router.afterEach(async (to, from, next) => {
    //     // console.log({
    //     //     page_location: `https://www.lovestruck.com${window.location.pathname}`,
    //     //     page_title: `${window.location.pathname}`
    //     // })
    //     window.gtag && gtag('event', 'page_view', {
    //         // page_location: `https://www.lovestruck.com${window.location.pathname}`,
    //         page_location: window.location.href,
    //         page_title: `${window.location.pathname}`,
    //     });
    // })

    return router
}
