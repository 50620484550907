<template>
  <div v-if="showTop">
    <div v-if="showMobileTop" class="pageTitleBar pt-3">
      <div v-if="showMobileTopNav" class="pb-2 border-bottom" style="height: 33px">
        <div style="position: absolute; left: 15px; top: 11px">
          <a v-if="showBack" href="javascript:;" @click="goback" class="p-2">
            <i class="lsicon arrow_left_y"></i>
          </a>
          <!-- <div
            style="display: inline-block"
            @click="barIconClick('matchmaker_chat')"
          >
            <i class="lsicon matchmaker-s"></i>
            <span class="ml-1">
              <span
                class="badge bg-lsred rounded-pill"
                style="position: relative; right: 0; top: -3px"
                >{{ unreadMatchmakerChatCnt }}</span
              ></span
            >
          </div> -->
          <!-- <div
            v-if="showDateCredit"
            @click="barIconClick('date_credit')"
            class="ml-2"
            style="display: inline-block"
          >
            <i class="lsicon date_credit"></i>
            <span class="ml-1 mr-3">{{ dateCredit }}</span>
          </div> -->
          <!-- <div
            v-if="allowEvent > 0 && showEventTicket"
            @click="barIconClick('event_ticket')"
            style="display: inline-block"
          >
            <i class="lsicon event_ticket"></i>
            <span class="ml-1">{{ eventTicket }}</span>
          </div> -->
        </div>

        <div @click="titleClick" class="text-capitalize text-center font-weight-normal" v-html="pageTitle"></div>

        <div style="position: absolute; right: 15px; top: 8px">
          <div v-if="showFilter" @click="barIconClick('match_filter')" style="display: inline-block">
            <i class="lsicon match-filter lsicon-30 lsicon-y-o"></i>
          </div>
        </div>
      </div>
      <!-- <div
        v-if="showFilter"
        @click="filterClick"
        class="text-center pb-2 cursor"
      >
        <i class="lsicon match-filter lsicon-25 mr-1" style="top: 7px"></i
        >{{ $t("match_filter") }}
      </div> -->
    </div>
    <div class="nav-wrap tab-icons bg-white">
      <!-- <div v-if="headPhoto" class="nav-left-icon">
        <a @click="myAccountPage" class="info-number me-hover" href="javascript:;">
          <img :src="headPhoto" class="img-responsve img-round" />
        </a>
      </div> -->
      <!-- <div
        v-if="!showMobileTopNav && showBack"
        class="nav-left-icon"
        style="border: none"
      >
        <a @click="goback" class="info-number me-hover" href="javascript:;">
          <i class="lsicon arrow_left_y"></i>
          
        </a>
      </div> -->
      <ul id="topNav" class="d-flex justify-content-between page6-2 mb-0" style="
          padding-inline-start: 0;
          padding-top: 6px;
          padding-bottom: 6px;
          width: 100%;
        ">
        <!-- <li
          v-if="!showMobileTopNav && !isMatchmakerChat"
          role="presentation"
          :class="curNav == 'matchmaker' ? 'active' : ''"
          style="top: -15px; margin: 0 10px"
        >
          <a class="info-number" @click="matchmakerLink" href="javascript:;">
            <span
              class="nav-icon icon-matchmaker-y"
              style="
                height: 22px;
                width: 22px;
                background-size: 22px !important;
              "
            ></span>
            <span
              v-if="unreadMatchmakerChatCnt > 0"
              class="text-capitalize topnavtitle"
              ><span
                class="badge bg-lsred rounded-pill"
                style="position: relative; right: 0"
                >{{ unreadMatchmakerChatCnt }}</span
              ></span
            >

            <span v-else style="display: block">&nbsp;</span>
          </a>
        </li> -->
        <!-- <li
          v-if="!showMobileTopNav && showDateCredit"
          role="presentation"
          :class="curNav == 'date_credit' ? 'active' : ''"
          style="top: -15px; margin: 0 10px"
        >
          <a class="info-number" @click="DateCreditLink" href="javascript:;">
            <span
              class="nav-icon icon-date-credit"
              style="
                height: 22px;
                width: 22px;
                background-size: 22px !important;
              "
            ></span>
            <span class="text-capitalize topnavtitle">{{ dateCreditCnt }}</span>
          </a>
        </li> -->

        <!-- <li
          v-if="allowEvent > 0 && !showMobileTopNav && showEventTicket"
          role="presentation"
          :class="curNav == 'event_ticket' ? 'active' : ''"
          style="top: 5px; margin: 0 10px"
        >
          <a class="info-number" @click="eventTicketLink" href="javascript:;">
            <span
              class="nav-icon icon-event-ticket"
              style="
                height: 22px;
                width: 22px;
                background-size: 22px !important;
              "
            ></span>
            <span class="text-capitalize topnavtitle">{{
              eventTicketCnt
            }}</span>
          </a>
        </li> -->
        <!-- <li role="presentation" :class="curNav == 'match' ? 'active' : ''">
          <a class="info-number" @click="matchListLink" href="javascript:;">
            
            <span class="nav-icon icon-matches"></span>
            <span class="text-capitalize topnavtitle">{{
              $t("search_txt")
            }}</span>
          </a>
        </li> -->

        <li v-if="allowMatch > 0" role="presentation" :class="curNav == 'match' ? 'active' : ''">
          <a class="info-number" @click="matchmakingLink" href="javascript:;">
            <div class="position-relative" style="display: inline-block">
              <span v-if="membership_level_id > 1 && matchTotal" class="countNum" style="top: -6px; right: -10px">{{
                matchTotal }}</span>

              <span class="nav-icon icon-matches"></span>
            </div>
            <!-- <span class="text-capitalize topnavtitle">{{
              $t("matche_txt")
            }}</span> -->
          </a>
        </li>

        <!-- <li
          v-if="allowFreePage > 0"
          role="presentation"
          :class="curNav == 'free-page' ? 'active' : ''"
        >
          <a class="info-number" @click="FreePageLink" href="javascript:;" :style="allowFreePage==1?'width: 100px':''">
            <span class="nav-icon icon-matche-preview"></span>
            <span class="text-capitalize topnavtitle" style="width: 93px">Lovestruck</span>
          </a>
        </li> -->
        <!-- <li
          v-if="verifyPending > 0 || accountPaused > 0"
          role="presentation"
          :class="(curNav == 'verify-pending' || curNav == 'account-paused') ? 'active' : ''"
        >
          <a class="info-number" @click="verifyPendingLink" href="javascript:;" :style="(verifyPending==1 || accountPaused==1)?'width: 100px':''">
            <span class="nav-icon icon-matche-preview"></span>
            <span class="text-capitalize topnavtitle" style="width: 100px">Lovestruck</span>
          </a>
        </li> -->

        <!-- <li
          v-if="allowEvent > 0"
          role="presentation"
          @click="eventListLink"
          :class="curNav == 'event' ? 'active' : ''"
        >
          <a class="info-number" href="javascript:;">
              <div class="position-relative" style="display: inline-block;">
            <span v-if="totalUpcomingEvent" class="badge bg-ls-g">{{
              totalUpcomingEvent
            }}</span>
            <span class="nav-icon icon-event"></span>
            </div>
            <span class="text-capitalize topnavtitle">{{
              $t("speed_dating_txt")
            }}</span>
          </a>
        </li> -->

        <!-- <li role="presentation" :class="curNav == 'matchmaker' ? 'active' : ''">
          <a class="info-number" @click="matchmakerLink" href="javascript:;">
            <span v-if="unreadMatchmakerChatCnt" class="badge bg-lsred">{{
              unreadMatchmakerChatCnt
            }}</span>
            <span class="nav-icon icon-matchmaker"></span>
            <span class="text-capitalize topnavtitle matchmaker">{{
              $t("matchmaker_txt")
            }}</span>
          </a>
        </li> -->

        <!-- <li role="presentation" :class="curNav == 'match' ? 'active' : ''">
          <a class="info-number" @click="matchListLink" href="javascript:;">
            <span
              v-if="matchAvailableCnt"
              class="badge bg-lsred rounded-pill"
              >{{ matchAvailableCnt }}</span
            >
            <span class="nav-icon icon-matches"></span>
            <span class="text-capitalize topnavtitle">{{
              $t("matche_txt")
            }}</span> -->
        <!-- </a>
        </li> -->

        <li v-if="allowChat" role="presentation" :class="curNav == 'chat' ? 'active' : ''">
          <!-- :title="$t('chat_txt')" -->
          <a class="info-number" @click="chatLink" href="javascript:;" :style="allowFreePage == 1 || verifyPending == 1 || accountPaused == 1
              ? 'width: 100px'
              : ''
            ">
            <div class="position-relative" style="display: inline-block">
              <span v-if="unreadChatCnt" class="badge bg-ee1 text-white">{{
                unreadChatCnt
              }}</span>
              <i class="fa-regular fa-messages"></i>
              <!-- <span class="nav-icon icon-dates" style="top: 3px"></span> -->
            </div>
          </a>
        </li>

        <li role="presentation" :class="curNav == 'account' ? 'active' : ''">
          <a class="info-number" @click="MyAccountLink" href="javascript:;" :style="allowFreePage == 1 || verifyPending == 1 || accountPaused == 1
              ? 'width: 100px'
              : ''
            "><i class="fa-regular fa-user"></i>
            <!-- <span v-if="unreadChatCnt" class="badge bg-lsred">{{unreadChatCnt}}</span> -->
            <!-- <span class="nav-icon icon-account"></span> -->
            <!-- :title="$t('Me')" <span class="text-capitalize topnavtitle">{{ $t("Me") }}</span> -->
          </a>
        </li>
      </ul>
    </div>
    <!-- <diamondPopup ref="diamondPopup" @scheduleNow="scheduleNowHander" />
    <scheduleConsultation ref="scheduleConsultation" /> -->
  </div>
</template>
<script>
// import func from "vue-editor-bridge";
// import diamondPopup from "./diamondPopup";
// import scheduleConsultation from "../common/scheduleConsultation";
export default {
  name: "topNav",
  props: {
    showFilter: {
      type: Boolean,
      default: false,
    },
    headPhoto: {
      type: String,
      default: "",
    },
    pageTitle: {
      type: String,
      default: "",
    },
    dateCredit: {
      type: Number,
      default: 0,
    },
    eventTicket: {
      type: Number,
      default: 0,
    },
    showBack: {
      type: Boolean,
      default: false,
    },
    showMobileTop: {
      type: Boolean,
      default: true,
    },
  },
  //   components: {
  //     diamondPopup,
  //     scheduleConsultation,
  //   },
  data() {
    return {
      showTop: true,
      // showAll: true,
      matchTotal: 0,
      matchAvailableCnt: 0,
      dateUpcomingCnt: 0,
      unreadChatCnt: 0,
      unreadMatchmakerChatCnt: 0,
      eliteCnt: 0,
      totalUpcomingEvent: 0,
      allowLike: 1,
      allowMatchmaking: 1,
      allowEvent: 0,
      allowMatch: 1,
      allowChat: 1,
      allowElite: 0,
      allowFreePage: 0,
      verifyPending: 0,
      accountPaused: 0,
      curNav: "elite",
      curSite: this.$store.getters.curSite,
      //   showMobileTop: true,
      showMobileTopNav: true,
      showDateCredit: false,
      showEventTicket: false,
      dateCreditCnt: 0,
      eventTicketCnt: 0,
      userInfo: this.$storage.get("userInfo"),
      getCountTimer: null,
      isMatchmakerChat: false,
      membership_level_id: 1,
    };
  },
  methods: {
    hide() {
      this.showTop = false;
    },
    setMatchTotal(val) {
      this.matchTotal = val;
      clearInterval(self.getCountTimer);
    },
    goback() {
      //   this.$emit("navBack");
      //   if (this.routerBack) {
      this.$router.back();
      //   }
    },
    filterClick() {
      this.$router.push({ name: "profile-requirement" });
    },
    titleClick() {
      this.$emit("titleClickEvent");
    },
    barIconClick(type) {
      if (type == "event_ticket") {
        this.$router.push({ name: "payment-event", query: { top_nav: 0 } });
      } else if (type == "date_credit") {
        this.$router.push({ name: "payment-date", query: { top_nav: 0 } });
      } else if (type == "match_filter") {
        this.filterClick();
      } else if (type == "matchmaker_chat") {
        this.matchmakerLink();
      }

      //
    },
    // setPageTitle(title) {
    //   this.page_title = title;
    // },
    setClientUnread(type, num) {
      if (type == "add") {
        this.unreadChatCnt += num;
      } else if (type == "set") {
        this.unreadChatCnt = num;
      } else {
        if (this.unreadChatCnt < num) {
          this.unreadChatCnt = 0;
        } else {
          this.unreadChatCnt -= num;
        }
      }
    },
    setMatchmakerUnread(type, num) {
      this.setClientUnread(type, num);
      //   if (type == "add") {
      //     this.unreadMatchmakerChatCnt += num;
      //   } else {
      //     if (this.unreadMatchmakerChatCnt < num) {
      //       this.unreadMatchmakerChatCnt = 0;
      //     } else {
      //       this.unreadMatchmakerChatCnt -= num;
      //     }
      //   }
    },
    settingLink() {
      this.$router.push({ name: "profile-setting" });
    },
    FreePageLink() {
      let self = this;
      let membership_has_expired = _.get(
        self.userInfo,
        "membership_has_expired",
        0
      );
      console.log("renew-payment", membership_has_expired, self.userInfo);
      if (membership_has_expired) {
        self.$router.push({ name: "renew-payment" });
      } else {
        self.$router.push({ name: "free-index" });
      }
    },
    verifyPendingLink() {
      // console.log("this.accountPaused=", this.accountPaused);
      if (this.accountPaused == 1) {
        this.$router.push({ name: "account-paused" }); //
      } else {
        this.$router.push({ name: "verify-pending" });
      }
    },
    DateCreditLink() {
      let self = this;

      // self.$storage.set(
      //   "venue-d-user",
      //   _.get(data, {
      //     client_id: _.get(self.targetClient, "client_id"),
      //     first_name: _.get(self.targetClient, "first_name"),
      //     age: _.get(self.targetClient, "age"),
      //     gender: _.get(self.targetClient, "gender"),
      //     photo_url: _.get(self.targetClient, "photo_url"),
      //   }),
      //   60
      // );
      // setTimeout(function () {
      self.$router.push({ name: "payment-date", query: { top_nav: 0 } });
      // }, 800);
    },
    eventTicketLink() {
      this.$router.push({ name: "payment-event", query: { top_nav: 0 } });
    },
    myAccountPage() {
      //   this.$router.push({ name: "profile-my-account" });
      this.$router.push({ name: "profile-me" });
    },
    eliteListLink() {
      this.$router.push({ name: "elite-list" });
    },
    matchListLink() {
      this.$router.push({ name: "match-new2" });
      //   this.$router.push({ name: "search-list" });
      //   this.$router.push({ name: "match-list" });
    },
    likeLink() {
      this.$router.push({ name: "like-list" });
      //   this.$router.push({ name: "search-list" });
      //   this.$router.push({ name: "match-list" });
    },
    matchmakingLink() {
      let self = this;

      //    let dia_call_back = _.get(self.userInfo, "dia_call_back", 0);

      if (self.membership_level_id > 1) {
        self.$router.push({ name: "matchmaking-new-matches" });
      }
      //    else if(_.get(self.userInfo, "city.has_diamond", 0) == 1 && self.membership_level_id<=1 ){//
      //         self.$router.push({ name: "matchmaking-callback" });
      //    }
      //    else if(self.membership_level_id<=1 ){//
      //         self.$router.push({ name: "op-match-list" });
      //    }
      //    else if(dia_call_back > 0){
      //     self.$router.push({ name: "matchmaking-callback" });
      //     }
      else {
        // self.$router.push({ name: "op-match-list" });
        self.$router.push({ name: "matchmaking-callback" });
        // self.$router.push({ name: "matchmaking-index" });
      }

      //   this.$router.push({ name: "search-list" });
      //   this.$router.push({ name: "match-list" });
    },
    dateListLink() {
      this.$router.push({ name: "date-1on1" });
    },
    MyAccountLink() {
      //   this.$router.push({ name: "profile-my-account" });
      this.$router.push({ name: "profile-me" });
    },
    eventListLink() {
      //   this.$router.push({ name: "event-list" });
      this.$router.push({ name: "new-event-list" });
    },
    matchmakerLink() {
      let self = this;

      let is_verified = _.get(self.userInfo, "is_verified", 0);
      //   console.log("is_verified", is_verified, self.userInfo);
      //   is_verified = 0;
      if (is_verified == 0) {
        self.$router.push({
          name: "matchmaker-chat-unerified",
        });

        return false;
      }

      let paths = location.pathname.replace("/", "").split("/");
      let urlpart = [paths[0], paths[1], "chat/matchmaker"].join("/");
      location.href = `/${urlpart}`;
      //   this.$router.push({ name: "matchmaker-chat" });
    },

    chatLink() {
      let self = this;
      let is_verified = _.get(self.userInfo, "is_verified", 0);
      self.$storage.set("chat-ref", location.href);
      if (is_verified == 1) {
        self.$router.push({
          name: "client-chat",
        });
      } else {
        self.$router.push({
          name: "client-chat",
          query: { clientId: _.get(self.userInfo, "matchmaker_chat_id", 0) },
        });
      }

      //   let self = this;
      //   let paths = location.pathname.replace("/", "").split("/");
      //   let urlpart = [paths[0], paths[1], "employee/chat"].join("/");
      //   let token = self.$storage.get("token");
      //   self.$storage.set("ls-ref", location.href);

      //   location.href = `/${urlpart}?token=${token}`;
    },
    getCounts() {
      let self = this;

      //   console.log("getCounts");
      let cacheKey = "my-count";
      let data = self.$storage.get(cacheKey);
      if (data) {
        // self.matchAvailableCnt = _.get(data, "match_available_cnt", 0);
        // self.dateUpcomingCnt = _.get(data, "date_upcoming_cnt", 0);
        // self.unreadMatchmakerChatCnt = _.get(
        //   data,
        //   "unread_matchmaker_chat_cnt",
        //   0
        // );
        // self.unreadChatCnt =
        //   _.get(data, "unread_chat_cnt", 0) + self.unreadMatchmakerChatCnt;

        // self.totalUpcomingEvent = _.get(data, "total_upcoming_event", 0);
        // self.dateCreditCnt = _.get(data, "date_credits", 0);
        // self.eventTicketCnt = _.get(data, "event_tickets", 0);
        self.matchTotal = _.get(data, "new_match_total", 0);
        // if (self.curNav != "chat") {
        self.unreadChatCnt = _.get(data, "unread_chat_cnt", 0);
        // }
        // self.unreadChatCnt = 3
        // self.unreadMatchmakerChatCnt = 2;
        return;
      }

      if (!self.userInfo) {
        // console.log("my count, no userInfo");
        return false;
      }
      self.$api
        .getClientCount(self.$store.state)
        .then((rs) => {
          self.matchTotal = _.get(rs, "new_match_total", 0);
          //   self.matchAvailableCnt = _.get(rs, "match_available_cnt", 0);
          //   self.dateUpcomingCnt = _.get(rs, "date_upcoming_cnt", 0);

          //   self.dateCreditCnt = _.get(rs, "date_credits", 0);
          //   self.eventTicketCnt = _.get(rs, "event_tickets", 0);
          //   self.unreadMatchmakerChatCnt = _.get(
          //     rs,
          //     "unread_matchmaker_chat_cnt",
          //     0
          //   );
          //   if (self.curNav != "chat") {
          self.unreadChatCnt = _.get(rs, "unread_chat_cnt", 0);
          //   }
          //   self.unreadChatCnt = 3

          //   self.totalUpcomingEvent = _.get(rs, "total_upcoming_event", 0);
          //   self.unreadMatchmakerChatCnt = 2;

          self.$storage.set(cacheKey, rs, 5);
        })
        .catch((error) => {
          self.$layer.closeAll("loading");
          console.log(error);
        });
    },
  },
  mounted() {
    let self = this;
    // console.log("top nav mounte");
    // console.log('userinfo', self.userInfo)
    let w_w = $(window).width();
    if (w_w <= 576) {
      //   self.showDateCredit = false;
      self.showMobileTopNav = true;
      //   $("#topNav").css({ width: `${w_w}px` });
    } else {
      //   $("#topNav").css({ width: `400px` });
      //   self.showDateCredit = true;
      self.showMobileTopNav = false;
    }
    let isChatPage = false;
    let city_name =
      self.curSite.name_long == "China" ? "Shenzhen" : self.curSite.name_long;

    self.membership_level_id = _.get(self.userInfo, "membership_level_id", 0);

    document.title = self.$t("page_title").replace("[city_name]", city_name);
    // console.log("top nav mounted", self.$t("page_title"), self.curSite);
    self.curNav = self.$route.name;
    if (
      self.$route.name == "profile-paused" ||
      self.$route.name == "profile-unverified" ||
      self.$route.name == "match-new" ||
      self.$route.name == "match-list" ||
      self.$route.name == "search-list" ||
      self.$route.name == "match-intro" ||
      self.$route.name == "match-new2"
    ) {
      self.curNav = "match";
    } else if (self.$route.name == "date-1on1") {
      self.curNav = "date";
    } else if (
      self.$route.name == "client-chat" ||
      self.$route.name == "client-tim-chat"
    ) {
      self.curNav = "chat";
      //clientId=employee
      let clientId = _.get(self.$route, "query.clientId", 0);
      if (clientId == "employee") {
        self.isMatchmakerChat = true;
      }

      isChatPage = true;
    } else if (
      self.$route.name == "new-event-list" ||
      self.$route.path.indexOf("event/") > -1
    ) {
      self.curNav = "event";
    } else if (self.$route.path.indexOf("/like") > -1) {
      self.curNav = "like";
    } else if (self.$route.path.indexOf("/matchmaking") > -1) {
      self.curNav = "match";
    } else if (self.$route.name == "op-match-list") {
      self.curNav = "match";
    } else if (self.$route.name == "elite-list") {
      self.curNav = "elite";
    } else if (self.$route.name == "profile-me") {
      self.curNav = "account";
    } else if (self.$route.path.indexOf("profile/") > -1) {
      let from = _.get(self.$route, "query.from");

      //   if (from == "search" || from == "match") {
      //     self.curNav = "match";
      //   } else if (from == "chat") {
      //     self.curNav = "chat";
      //   } else if (from == "elite") {
      //     self.curNav = "elite";
      //   } else if (from == "matchmaker") {
      //     self.curNav = "matchmaker";
      //   } else if (from == "free") {
      //     self.curNav = "free-page";
      //   } else {
      //     self.curNav = "account";
      //   }
      self.curNav = "match";
    } else if (self.$route.name.indexOf("matchmaker-chat") > -1) {
      self.curNav = "matchmaker";
    } else if (
      self.$route.name == "free-index" ||
      self.$route.name == "match-preview" ||
      self.$route.name == "client-verification" ||
      self.$route.name == "renew-payment"
    ) {
      self.curNav = "free-page";
    }

    if (isChatPage) {
      setTimeout(function () {
        $("body").addClass("noy-scroll");
      }, 500);
    } else {
      setTimeout(function () {
        $("body").removeClass("noy-scroll");
      }, 500);
    }

    // self.allowEvent = _.get(self.curSite, "allow_events", 0);
    // // //Show "Matches" button in nav bar only if is_verified=0
    // // // self.allowMatch = _.get(self.userInfo, "is_verified", 0) == 0 ? 1 : 0;
    // // self.showEventTicket = self.allowEvent > 0 ? true : false;
    // // self.showDateCredit =
    // //   _.get(self.userInfo, "is_verified", 0) == 1 ? true : false;
    // let is_verified = _.get(self.userInfo, "is_verified", 0);
    // let client_status_id = _.get(self.userInfo, "client_status_id", 0);
    // let membership_level_id = _.get(self.userInfo, "membership_level_id", 0);
    // // is_verified = 1;
    // // client_status_id = -1
    // // membership_level_id = 1;
    // if (membership_level_id > 2) {
    //   //   self.allowEvent = 0;
    //   self.allowMatch = 1;
    //   self.allowElite = 1;
    //   self.allowChat = 1;
    //   self.allowFreePage = 0;
    //   self.showEventTicket = true;
    //   self.showDateCredit = true;
    // } else if (membership_level_id <= 2) {
    //   self.allowEvent = 0;
    //   self.allowMatch = 0;
    //   self.allowElite = 0;
    //   self.allowChat = 1;
    //   self.allowFreePage = 1;
    //   self.showEventTicket = false;
    //   self.showDateCredit = false;
    // } else {
    //   self.allowEvent = 0;
    //   self.allowMatch = 0;
    //   self.allowElite = 0;
    //   self.allowChat = 0;
    //   self.allowFreePage = 1;
    //   self.showEventTicket = false;
    //   self.showDateCredit = false;
    // }

    // if(membership_level_id > 1 && is_verified == 0){
    //     self.allowEvent = 0;
    //   self.allowMatch = 0;
    //   self.allowElite = 0;
    //   self.allowChat = 1;
    //   self.verifyPending = 1;
    //   self.showEventTicket = false;
    //   self.showDateCredit = false;
    // }else if(membership_level_id > 1 && client_status_id == -1){
    //     self.allowEvent = 0;
    //   self.allowMatch = 0;
    //   self.allowElite = 0;
    //   self.allowChat = 1;
    //   self.accountPaused = 1;
    //   self.showEventTicket = false;
    //   self.showDateCredit = false;
    // }
    // console.log(self.verifyPending,self.accountPaused)

    if (!self.getCountTimer) {
      self.getCountTimer = setInterval(function () {
        self.getCounts();
      }, 5000);
    }

    // console.log("curSite=", self.curSite);
    setTimeout(function () {
      $(document).scrollTop(0);
    }, 500);

    setTimeout(function () {
      self.getCounts();
    }, 1000);

    setTimeout(function () {
      clearInterval(self.getCountTimer);
    }, 60000);
  },
};
</script>
<style scoped>
.pageTitleBar {
  width: 100%;
  background: #fff;
  position: relative;
  /* border-bottom: 1px solid #eee; */
}

.pageTitleBar .barIcon {
  position: absolute;
  left: 15px;
  top: 15px;
}
</style>