<template>
  <div>
    <!-- class="text-left bg-white p-3 rounded-10 border" -->
    <!-- <div class="bg-white col-lg-10 col-md-12 col-sm-12 mx-auto rounded p-3 text-left"> -->
    <!-- <ul class> -->
    <div class="mb-3" v-if="type == 'oneline'">
      <div
        class="bg-light rounded px-2"
        :class="isFocus ? 'border-focus' : 'border-light'"
      >
        <div class="d-flex justify-content-start">
          <b-form-input
            type="number"
            :placeholder="$t('payment_3col_card_num')"
            class="card-number"
            id="cardNumberInput"
            maxlength="16"
            @change="onChangedEvent('number')"
            v-model="card.number"
            :formatter="numberFormatter"
          />
          <b-form-input
            type="tel"
            maxlength="5"
            v-model="expiryDate"
            :placeholder="$t('mm_yy')"
            @keyup.delete="deleteExpiry"
            :formatter="expiryInput"
            class="card-expire"
          />
          <b-form-input
            type="number"
            :formatter="cvvFormatter"
            v-model="card.cvv"
            @change="onChangedEvent('cvv')"
            placeholder="CVC"
            class="card-cvc"
          />
        </div>
      </div>
    </div>
    <!-- <div class="mb-3">
      <div class="bg-light rounded">
        <b-form-input
          type="number"
          maxlength="16"
          @change="onChangedEvent('number')"
          v-model="card.number"
          class="form-control-mb px-2"
          required
          :formatter="numberFormatter"
          :placeholder="$t('payment_3col_card_num')"
        />
      </div>
    </div> -->
    <div v-if="type == 'twolines'" class="credit-card-box bg-white">
      <div class="px-3">
        <!-- <input type="text" maxlength="16"
            required
            id="cardNumberInput2"
            @change="onChangedEvent('number')"
            v-model="card.number"
            class="lsm-input px-2"
            :placeholder="$t('payment_3col_card_num')" /> -->
        <b-form-input
          type="number"
          maxlength="16"
          required
          id="cardNumberInput2"
          @change="onChangedEvent('number')"
          v-model="card.number"
          class="cardNumber"
          :placeholder="$t('payment_3col_card_num')"
        />
        <div class="card-imgs">
          <img
            src="https://cdn-cf2.lovestruck.com/upload-s/gen/img/ls-app-7/card-1.png"
            height="24"
            class=""
          /><img
            src="https://cdn-cf2.lovestruck.com/upload-s/gen/img/ls-app-7/card-2.png"
            height="24"
            class=""
          /><img
            src="https://cdn-cf2.lovestruck.com/upload-s/gen/img/ls-app-7/card-3.png"
            height="24"
            class=""
          />
        </div>
      </div>
      <div class="card-line"></div>

      <div class="d-flex justify-content-between px-3">
        <div>
          <b-form-input
            type="tel"
            maxlength="5"
            v-model="expiryDate"
            required
            @keyup.delete="deleteExpiry"
            :formatter="expiryInput"
            class="cardExpire border-0"
            placeholder="MM/YY"
          />
        </div>
        <div class="card-vline"></div>
        <div class="" style="position: relative; width: 40%; height: 38px">
          <b-form-input
            type="number"
            :formatter="cvvFormatter"
            v-model="card.cvv"
            required
            @change="onChangedEvent('cvv')"
            class="cardCvc"
            :placeholder="$t('cvv_txt')"
          />
          <div class="card-cvc-img">
            <img
              src="https://cdn-cf2.lovestruck.com/upload-s/gen/img/ls-app-7/card-cvc.png"
              height="24"
              class=""
            />
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="">
        <div class>
          <div class="form-control-mb">
            {{$t('payment_3col_expiry')}}
            <b-form-select v-model="card.expiryMonth" @change="onChangedEvent" style="width:70px">
              <option value>{{$t('month_format')}}</option>
              <option v-for="m in months" :key="m" :value="m">{{m}}</option>
            </b-form-select>/
            <b-form-select
              v-if="years"
              v-model="card.expiryYear"
              @change="onChangedEvent"
              style="width:100px"
            >
              <option value>{{$t('year_format')}}</option>
              <option v-for="y in years" :key="y" :value="y">{{y}}</option>
            </b-form-select>
          </div>
        </div>
      </div>
      <div class="">
        <div class>
          <input
            type="text"
            v-model="card.cvv"
            @change="onChangedEvent"
            class="form-control-mb"
            :placeholder="$t('cvv_txt')"
          />
        </div>
    </div>-->
    <div v-if="needEmail" class="mb-3">
      <div class="bg-light rounded">
        <b-form-input
          type="email"
          v-model="card.email"
          maxlength="64"
          required
          @change="onChangedEvent('email')"
          class="form-control-mb px-2"
          :placeholder="$t('web_header_email')"
        />
      </div>
    </div>
    <!-- </ul> -->
    <div></div>
    <div v-if="saveNT" class="mt-3">
      <b-check
        v-model="card.saveNextTime"
        @change="onChangedEvent"
        name="check-button"
        >Save this card for next time</b-check
      >
    </div>

    <div v-if="supportLine" class="mt-4">
      <p class="text-979">Supported cards</p>
      <p>
        <i
          class="fab fa-cc-visa position-none text-979 font-2rem"
          aria-hidden="true"
        ></i>
        <i
          class="fab fa-cc-mastercard position-none text-979 ml-2 font-2rem"
          aria-hidden="true"
        ></i>
        <i
          class="fab fa-cc-amex position-none text-979 ml-2 font-2rem"
          aria-hidden="true"
        ></i>
        <i
          class="fab fa-cc-diners-club position-none text-979 ml-2 font-2rem"
          aria-hidden="true"
        ></i>
        <i
          class="fab fa-cc-discover position-none text-979 ml-2 font-2rem"
          aria-hidden="true"
        ></i>
        <i
          class="fab fa-cc-jcb position-none text-979 ml-2 font-2rem"
          aria-hidden="true"
        ></i>
      </p>
    </div>
    <!-- </div> -->
  </div>
</template>
<script>
export default {
  name: "creditCardCom",
  props: {
    supportLine: {
      type: Boolean,
      default: true,
    },
    needEmail: {
      type: Boolean,
      default: false,
    },
    saveNT: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: "oneline",
    },
  },
  data() {
    return {
      isFocus: false,
      card: {
        holderName: "",
        expiryYear: "",
        expiryMonth: "",
        cvv: "",
        number: "",
        saveNextTime: true,
        email: "",
      },
      expiryDate: "",
      selectedMonth: "",
      pickerMinDate: null,
      pickerMaxDate: null,
      pickerPH: "MM/YY",
      years: [],
      months: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ],
    };
  },
  mounted() {
    let self = this;
    // self.$parent.closeLoading();
    self.createYears();
    // console.log("userInfo", self.$storage.get("userInfo"));
    self.card.email = _.get(self.$storage.get("userInfo"), "email");
    let now = new Date();
    self.pickerMinDate = self.$moment(now);
    self.pickerMaxDate = self.$moment(`${now.getFullYear() + 10}/12`);

    let w_w = $(window).width();
    if (w_w <= 360) {
      $(".card-expire").on("focus", function () {
        $(".card-cvc").show();
      });

      $(".card-number").on("focus", function () {
        $(".card-cvc").hide();
      });
    }

    $(".card-number,.card-expire,.card-cvc").on("focus", function () {
      self.isFocus = true;
    });
    $(".card-number,.card-expire,.card-cvc").on("blur", function () {
      self.isFocus = false;
    });
    // setTimeout(function () {
    //   if (self.type == "twolines") {
    //     $("#cardNumberInput2").focus();
    //     // console.log("set focus");
    //   }
    // }, 1000);
  },
  methods: {
    setInputFocus() {
      if (this.type == "twolines") {
        $("#cardNumberInput2").focus();
        console.log("set focus2");
      } else {
        $("#cardNumberInput").focus();
        console.log("set focus");
      }
    },
    setFocus(val) {
      this.isFocus = val;
    },
    numberFormatter(value) {
      //   console.log("numberFormatter", value);
      return value.replace(/[^\d]/g, "").substr(0, 16);
    },
    cvvFormatter(value) {
      return value.replace(/[^\d]/g, "").substr(0, 3);
    },
    expiryInput(value) {
      let self = this;
      let len = value.length;
      let num2Exp = /^\d{2}$/;
      let d = new Date();
      let tY = d.getFullYear();
      // console.log(value, tY.toString().substr(2, 2));
      if (len < 2) {
        return value.replace(/[^\d]/g, "");
      } else if (len == 2 && num2Exp.test(value)) {
        let n = parseInt(value);
        if (n > 12 || n <= 0) {
          return "";
        }
        return `${value}/`;
      } else if (len == 2 && !num2Exp.test(value)) {
        return value.replace(/[^\d]/g, "");
      } else if (len > 3) {
        let stY = parseInt(tY.toString().substr(2, 2));
        let ps = value.split("/");
        let p1 = ps[0].replace(/[^\d]/g, "").substr(0, 2);
        let p2 = parseInt(ps[1].replace(/[^\d]/g, "").substr(0, 2));

        if (isNaN(p2) || (p2 > 10 && p2 < stY)) {
          return `${p1}/`;
        }
        self.card.expiryMonth = p1;
        self.card.expiryYear = `20${p2}`;
        // console.log(self.card);
        self.$emit("valueChanged", self.card);
        return `${p1}/${p2}`;
      }

      return "";
    },
    deleteExpiry() {
      let self = this;
      self.expiryDate = "";
      self.card.expiryYear = "";
      self.card.expiryMonth = "";
      // console.log(self.card);
      self.$emit("valueChanged", self.card);
    },
    onChangedEvent(filed) {
      let self = this;

      // if (filed == "exp") {
      //   let expArr = self.expiryDate.split("/");
      //   self.card.expiryMonth = expArr[0];

      //   if (expArr[1].length == 2) {
      //     self.card.expiryYear = `20${expArr[1]}`;
      //   } else {
      //     self.card.expiryYear = expArr[1];
      //   }
      // }

      // console.log(self.card);
      self.$emit("valueChanged", self.card);
    },
    pickerSelected(moment) {
      let self = this;
      // let date = moment.format("YYYY-MM");
      // console.log(date);
      self.card.expiryYear = moment.format("YYYY");
      self.card.expiryMonth = moment.format("MM");
      // console.log(date, self.card.expiryYear, self.card.expiryMonth);
    },
    createYears() {
      let self = this;
      let now = new Date();
      let startYear = now.getFullYear();

      let years = [];

      for (let i = startYear; i < startYear + 12; i++) {
        years.push(i);
      }
      self.years = years;
      // console.log(years);
    },
  },
};
</script>
<style scoped>
.credit-card-box {
  position: relative;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
}
.form-control.cardCvc,
.form-control.cardExpire,
.form-control.cardNumber {
  font-size: 18px !important;
  font-weight: 500 !important;
  height: 50px !important;
  width: 100% !important;
  border: 0;
  webkit-box-shadow: none;
  box-shadow: none;
  padding: 0 0 0 3px;
}
.credit-card-box .card-cvc-img,
.credit-card-box .card-imgs {
  position: absolute;
  right: 15px;
  top: 12px;
}
.card-line {
  width: 100%;
  height: 1px;
  background: #d9d9d9;
}
.card-vline {
  width: 1px;
  height: 50px;
  background: #d9d9d9;
  margin: 0 1px;
}
.card-number,
.card-expire,
.card-cvc {
  /* display: inline-block; */
  height: 36px;
}
.card-number,
.card-expire,
.card-cvc {
  color: #999;
  outline: none !important;
}
.card-number.form-control:focus,
.card-number.form-control:active,
.card-expire.form-control:focus,
.card-cvc.form-control:focus {
  border-color: #f8f9fa !important;
  box-shadow: none !important;
  outline: none !important;
  /* background: #f8f9fa; */
  background: transparent;
}
.card-number:focus,
.card-expire:focus,
.card-cvc:focus {
  color: #000;
}
.card-number {
  width: 100%;
  /* min-width: 60%; */
}
.card-expire {
  text-align: center;
  padding-left: 6px;
  width: 65px;
}
.card-cvc {
  /* padding-left: 6px; */
  text-align: center;
  width: 46px;
}
@media (max-width: 360px) {
  .card-cvc {
    display: none;
  }
}
</style>