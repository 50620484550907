<template>
  <div class="bg-faf text-1d min-h100">
    <div class="page6-2 px-3" style="position: relative">
      <!-- <div class="pt-3 pl-3" style="position: absolute">
        <a href="javascript:;" @click="goback"
          ><i class="lsicon close_white lsicon-30"></i
        ></a>
      </div> -->
      <!-- <div class="d-flex justify-content-between pt-4">
        <div>
          <a href="javascript:;" @click="goback"
            ><i class="lsicon close_white lsicon-30"></i
          ></a>
        </div>
        <div>
          <b-img
            height="38"
            src="https://cdn-cf2.lovestruck.com/upload-s/gen/img/ls-app-6.2/LS_logo.png"
          ></b-img>
        </div>
        <div>&nbsp;</div>
      </div> -->
      <div style="height: 15vh"></div>
      <b-img width="220" center :src="levelLogo"></b-img>
      <!-- <div class="mb-1">
        <b-img
          center
          width="200"
          src="https://cdn-cf2.lovestruck.com/upload/gen/img/v12/Logo-v12@2x.png"
        ></b-img>
      </div>
      <div class="fw-600 text-ee1 text-center text-uppercase">
        - {{ selectedName }} -
      </div> -->
      <!-- <div class="text-center text-uppercase" :style="`color:${selectedColor}`">
        {{ selectedName }}
      </div> -->
      <div style="height: 10vh"></div>
      <div class="mb-4">
        <planCarousel ref="planCarousel"></planCarousel>
      </div>

      <div v-if="btnText" class="py-5 text-center">
        <b-button
          @click="bookClick"
          pill
          :variant="btnVariant"
          class="px-5 l-space2 text-uppercase w-75"
          style="height: 53px"
          >{{ btnText }}</b-button
        >
      </div>
    </div>

    <diaCallbackPopup ref="diaCallbackPopup" @afterPost="afterCallbackPost" />
  </div>
</template>
<script>
import helper from "../../utils/helper";
import planCarousel from "./planCarousel.vue";
import diaCallbackPopup from "../common/diaCallbackPopup";
export default {
  name: "upgrade-book",
  components: {
    planCarousel,
    diaCallbackPopup,
  },
  data() {
    return {
      selectedName: "",
      selectedColor: "",
      btnVariant: "ee1",
      btnText: null,
      client: null,
      levelLogo: null,
    };
  },
  mounted() {
    let self = this;
    let type = _.get(self.$route, "query.type", "matchmaking");

    self.$api
      .getClientMe(self.$store.state)
      .then((rs) => {
        let membership_level_id = _.get(rs, "client.membership_level_id");
        // membership_level_id = 2;
        if (membership_level_id >= 5) {
        }

        // if (membership_level_id <= 2) {
        //   type = "guest-starter";
        // }
        // else if (membership_level_id == 3) {
        //   type = "starter-gold";
        // } else if (membership_level_id == 4) {
        //   type = "silver-gold";
        // }
        let leveId = 2;
        if (type == "plus") {
          //   type = "any-diamond";
          self.selectedName = "plus";
          leveId = 3;
        }
        // else if (type == "gold") {
        //   type = membership_level_id == 4 ? "silver-gold" : "starter-gold";
        // }
        else {
          self.selectedName = "matchmaking";
          leveId = 4;
          //   type = "guest-starter";
        }

        // self.selectedName = type.split("-")[1];
        // self.selectedColor = helper.membershipColor(self.selectedName);
        self.levelLogo = helper.getMembershipPhotoById(leveId);
        self.btnVariant = "ee1";
        self.btnText = self.$t("m_continue_txt");

        // if (self.selectedName == "gold") {
        //   self.btnVariant = "ls-gold6";
        //   self.btnText = self.$t("get_gold");
        // } else if (self.selectedName == "diamond") {
        //   self.btnVariant = "ls-dia-g3";
        //   self.btnText = self.$t("get_diamond_txt");
        // } else {
        //   self.btnVariant = "ls-starter";
        // }

        self.$refs.planCarousel.changePlanName(type, 0, membership_level_id);

        self.$layer.closeAll("loading");
        self.client = _.get(rs, "client");
        // }
      })
      .catch((err) => {
        self.$layer.closeAll("loading");
        console.log(["me error", err]);
      });
  },
  methods: {
    afterCallbackPost() {
      let self = this;
      self.$router.push({ name: "profile-me" });
    },
    bookClick() {
      let self = this;
      //   self.callbackType = type;
      // this.$refs.scheduleConsultation.showModal(2);
      self.$refs.diaCallbackPopup.showModal(self.selectedName);
    },
    goback() {
      this.$router.back();
    },
  },
};
</script>