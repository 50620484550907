<template>
    <div class="bg-black min-h100 text-white text-center py-5">
        <div class="mx-auto" style="max-width: 480px !important">
        <h3 class="mb-4">Verified Members get more matches</h3>
        <p class="mb-4">
            Help us protect the Lovestruck community by showing that you're the person in your profile. 
            It's 100% free, fast and you'll be matched with other verified Members too. 
        </p>
        <ul class="text-left">
            <li>Take a quick video selfie</li>
            <li>There's no need to match your profile pic's hair or makeup</li>
            <li>It's never shared to your profile or with matches</li>
        </ul>

        <div class="my-5">
            <b-button
            @click="howitwork"
            variant="ls-gold-g2"
            pill
            size="lg"
            class="px-5 border-0 fw-600"
            >How it works</b-button
          >
        </div>
        <div>
            <b-button pill variant="light" class="px-5">Maybe later</b-button>
        </div>
    </div>
    </div>
</template>
<script>
export default {
    name: "verify-starts",
    mounted(){
        return {

        }
    },
    methods: {
        howitwork(){
            this.$router.push({ name: "verify-howitwork" });
        }
    }
}
</script>