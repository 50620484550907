<template>
  <div class="bg-faf min-h100 text-1d">
    <!-- <pageTop ref="pageTop" /> -->
    <div class="page6-2 p-3">
      <div class="pt-3-">
        <a href="javascript:;" @click="goback"
          ><i class="fa-regular fa-xmark text-ee1 fs-24"></i
        ></a>
      </div>
      <div v-if="errorMsg" class="alert alert-danger mt-3" role="alert">
        {{ errorMsg }}
      </div>
      <div
        class="d-flex justify-content-between mt-3 s30"
        :class="checkLableClass"
      >
        <div
          class="mr-3 btn border-0"
          :class="payMethod == 'card' ? style.cardOn : 'text-7e'"
        >
          <b-form-radio
            v-model="payMethod"
            @change="payMethodChange('card')"
            name="pay_method"
            value="card"
            >{{ $t("credit_card") }}</b-form-radio
          >
        </div>

        <div
          class="btn border-0"
          :class="payMethod == 'bank' ? style.cardOn : 'text-7e'"
        >
          <b-form-radio
            v-model="payMethod"
            @change="payMethodChange('bank')"
            name="pay_method"
            value="bank"
            >{{ $t("Bank Transfer") }}</b-form-radio
          >
        </div>
      </div>

      <div class="my-4">
        <div v-if="payMethod == 'card'" class="mb-4">
          <creditCardCom
            ref="creditCardCom"
            :supportLine="false"
            :saveNT="false"
            type="twolines"
            @valueChanged="receiveCard"
          />
        </div>

        <div
          v-if="
            selectedPlan && selectedPlan.productType != 'event' && newOrderCard
          "
          class="card bg-white mb-4"
        >
          <div class="p-3">
            <!-- <div class="d-flex justify-content-between"> -->
            <div>
              <b-img
                height="20"
                style="border: none !important"
                :src="plan_logo"
                class="mb-3"
              />
            </div>

            <div v-html="orderCard.title"></div>
            <!-- </div> -->
          </div>
          <hr class="mt-0 mb-3" />
          <!-- <div v-if="orderCard.amount_sub" class="px-3 mt-0 d-flex justify-content-between" v-html="orderCard.amount_sub">
              
          </div> -->
          <div class="px-3 d-flex justify-content-between mb-2">
            <div>{{ $t("original_price") }}</div>
            <div>{{ orderCard.original_price }}</div>
          </div>

          <div class="px-3 d-flex justify-content-between">
            <div>{{ $t("payment_3col_save") }}</div>
            <div>{{ orderCard.save }}</div>
          </div>

          <hr class="my-3" />
          <div class="card-body px-3 pt-1 bg-light">
            <div class="d-flex justify-content-between">
              <div class="fw-600">{{ $t("total_txt") }}:</div>
              <div class="fw-600">
                {{ orderCard.amount }}
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="selectedPlan && selectedPlan.productType != 'event'"
          class="card bg-white text-7e mb-4 border rounded"
        >
          <!-- <div class="p-3 d-flex justify-content-between"> -->

          <div class="p-3 text-center">
            <div v-if="plan_logo" class="mb-2">
              <b-img :height="plan_logo_h" :src="plan_logo" />

              <!-- <span class="fw-700 text-ls-y3 ml-2">{{selectedPlan.product.description}}</span> -->
            </div>
            <div v-else class="mb-2">
              <b-img
                height="30"
                src="https://cdn-cf2.lovestruck.com/upload-s/gen/img/ls-app-6.2/LS_logo.png"
              ></b-img>
              <span
                class="text-uppercase"
                :style="`color:${selectedPlan.product.color_code}`"
                >{{ selectedPlan.product.description }}</span
              >
            </div>
            <div class="text-7e" v-html="orderCard.title"></div>
          </div>

          <!-- <div>{{ orderCard.amount_per_month }}</div>
           
          </div> -->
          <!-- <div v-if="orderCard.amount_sub" class="px-3 mt-0 d-flex justify-content-between" v-html="orderCard.amount_sub"> -->
          <div class="border-bottom w-100"></div>
          <div v-if="showDiscountLine" class="px-3 my-3 fw-400">
            <div
              v-if="orderCard.original_price"
              class="d-flex justify-content-between mb-2"
            >
              <div>{{ $t("original_price") }}</div>
              <div>{{ orderCard.original_price }}</div>
            </div>

            <div
              v-if="orderCard.save"
              class="d-flex justify-content-between text-ee1"
            >
              <div class="text-capitalize">{{ $t("discount_txt") }}</div>
              <div>-{{ orderCard.save }}</div>
            </div>
          </div>
          <div class="border-bottom w-100"></div>
          <div class="p-3 text-7e">
            <div class="d-flex justify-content-between">
              <div class="fw-600">{{ $t("total_txt") }}:</div>
              <div class="fw-600">
                {{ orderCard.amount }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="selectedPlan && selectedPlan.productType == 'event'"
        class="card bg-white mb-4"
      >
        <div class="card-header px-3 py-2">{{ order.title }}</div>
        <div class="card-body p-3">
          <div class="d-flex justify-content-between">
            <div>{{ $t("lang_price") }}:</div>
            <div>
              {{ selectedPlan.currency.symbol }}{{ formatPrice2(extras.price) }}
            </div>
          </div>
          <hr class="mt-1 mb-2" />
          <div class="d-flex justify-content-between">
            <div>{{ $t("number_txt") }}:</div>
            <div>{{ extras.num }}</div>
          </div>
          <hr class="mt-1 mb-2" />
          <div class="d-flex justify-content-between">
            <div class="fw-600">{{ $t("total_txt") }}:</div>
            <div class="fw-600">
              {{ selectedPlan.currency.symbol }}{{ formatPrice2(order.amount) }}
            </div>
          </div>
        </div>
      </div>

      <div v-if="payMethod == 'card'" class="text-center mt-4">
        <!-- <a
          href="javascript:;"
          class="btn btn-lg px-5 btn-lg-pill fw-600"
          :class="style.payBtn"
          @click="payNow"
          >{{ payBtnTxt }}</a
        > -->
        <b-button
          @click="payNow"
          :disabled="canSubmit ? false : true"
          :variant="btnVariant"
          pill
          class="px-5 l-space2 text-uppercase w-100"
          style="height: 53px"
          >{{ payBtnTxt }}</b-button
        >

        <div>
          <b-img
            class="w-100"
            src="https://cdn-cf2.lovestruck.com/upload-m/gen/img/ls-app-6.2/stripe_banner@3x.png"
          ></b-img>
        </div>

        <!-- <div class="my-3 text-muted small">
          <i class="fa fa-lock"></i>
          {{ $t("secure_checkout") }}
        </div> -->
      </div>

      <div
        v-if="payMethod == 'card'"
        class="text-muted mt-4 fs-12 font-weight-light"
        v-html="paymentDesc"
      ></div>

      <div v-if="payMethod == 'bank' && client" class="my-5 text-1d">
        <div class="mb-3 px-3" v-html="$t('transfer_step_1')"></div>

        <div class="bankInfo">
          <div class="d-flex justify-content-star align-items-center item">
            <div class="name">{{ $t("Bank Name") }}</div>
            <div class="dev-line"></div>
            <div class="value">{{ client.city.bank_name }}</div>
          </div>
          <div class="d-flex justify-content-star align-items-center item">
            <div class="name">{{ $t("Account Name") }}</div>
            <div class="dev-line"></div>
            <div class="value">{{ client.city.bank_account_name }}</div>
          </div>
          <div class="d-flex justify-content-star align-items-center item">
            <div class="name">{{ $t("Account number") }}</div>
            <div class="dev-line"></div>
            <div class="value">{{ client.city.bank_account_number }}</div>
          </div>
          <div class="d-flex justify-content-star align-items-center item">
            <div class="name">{{ $t("amount_txt") }}</div>
            <div class="dev-line"></div>
            <div class="value">{{ orderCard.amount }}</div>
          </div>
        </div>

        <!-- <ul class="list-group list-group-flush bg-white">
          <li
            class="list-group-item d-flex justify-content-star align-items-center"
          >
            <div class="" style="width: 130px">{{ $t("Bank Name") }}</div>
            <div class="ml-3">{{ client.city.bank_name }}</div>
          </li>
          <li
            class="list-group-item d-flex justify-content-star align-items-center"
          >
            <div class="" style="width: 130px">{{ $t("Account Name") }}</div>
            <div class="small ml-3">
              {{ client.city.bank_account_name }}
            </div>
          </li>
          <li
            class="list-group-item d-flex justify-content-star align-items-center"
          >
            <div class="" style="width: 130px">{{ $t("Account number") }}</div>
            <div class="ml-3">{{ client.city.bank_account_number }}</div>
          </li>
          <li
            class="list-group-item d-flex justify-content-star align-items-center"
          >
            <div class="" style="width: 130px">{{ $t("amount_txt") }}</div>
            <div class="ml-3">{{ orderCard.amount }}</div>
          </li>
        </ul> -->
      </div>

      <div v-if="payMethod == 'bank'" class="my-4">
        <div class="mb-3 px-3" v-html="$t('transfer_step_2')"></div>

        <div class="text-center pb-5">
          <b-button
            @click="uploadReceipt"
            :variant="btnVariant"
            class="mt-2 px-5 l-space2 w-100 text-uppercase"
            pill
            style="height: 53px"
          >
            {{ $t("upload_receipt") }}
          </b-button>
        </div>
      </div>

      <!-- <div v-if="payMethod == 'bank'" class="my-4 text-center">
        <div class="mb-3" v-html="$t('transfer_step_2')"></div>
        <b-img
          width="220"
          style="border: none !important"
          @click="openApp"
          src="https://cdn-cf2.lovestruck.com/upload-s/gen/img/icons/2020/app_store_icons.png"
        />
      </div>

      <div v-if="payMethod == 'bank'" class="my-4 text-center">
        <div class="mb-3" v-html="$t('transfer_step_3')"></div>
        <div
          class="small font-weight-light"
          v-html="$t('transfer_step_3_desc')"
        ></div>
      </div> -->
    </div>

    <registerForm ref="registerForm" @userData="getUserData" />
  </div>
</template>
<script>
// import commonHead from "../common/head";
// import pageTop from "../common/pageTop";
import creditCardCom from "../common/creditCardCom";
import registerForm from "../common/registerForm.vue";
import helper from "../../utils/helper";
export default {
  name: "checkout",
  components: {
    // pageTop,
    creditCardCom,
    registerForm,
  },
  data() {
    return {
      client: null,
      logos: {},
      plan_logo: "",
      plan_logo_h: 16,
      userInfo: this.$storage.get("userInfo"),
      payMethod: "card",
      styleType: "diamond",
      btnVariant: "ls-gold-g2",
      checkLableClass: "ee1",
      style: {
        cardOn: "text-secondary",
        payBtn: "bg-silver",
      },
      orderCard: {
        amount: "",
        amount_sub: "",
      },
      selectedPlan: null,
      card: {
        holderName: "",
        expiryYear: "",
        expiryMonth: "",
        cvv: "",
        number: "",
      },
      order: {
        title: "",
        description: "",
        currency: "",
        amount: 0,
        divisionAmount: null,
      },
      address: {
        userName: "",
        email: "",
        address1: null,
        address2: null,
        city: null,
        state: null,
        postcode: null,
        country: null,
      },
      membership: {},
      paymentFlow: {
        notifyUrl: "",
        successUrl: "",
        failUrl: "",
      },
      repeat: {
        interval: null,
        startDate: "",
        endDate: "",
        order: null,
      },
      extras: null,
      api_base_url: "https://api.lovestruck.com/api/",
      web_base_url: "",
      requestPayUrl: "",
      curCity: this.$store.getters.curCity,
      isTest: false,
      country: "hongkong",
      currency_map: {
        AUD: "AUD",
        HKD: "HKD",
        SGD: "SGD",
        JPY: "JPY",
        EUR: "EUR",
        GBP: "GBP",
        MYR: "MYR",
        USD: "USD",
        INR: "INR",
        RMB: "CNY",
        THB: "THB",
        IDR: "",
        CAD: "",
        NTD: "",
        KRW: "",
      },
      paymentTotalStr: "",
      canSubmit: false,
      errorMsg: false,
      paymentDesc: "",
      cardFocus: false,
      needEmail: false,
      payBtnTxt: "",
      newOrderCard: false,
      showDiscountLine: false,
      //   curSite: this.$store.getters.curSite,
    };
  },
  mounted() {
    let self = this;
    setTimeout(function () {
      $("body").removeClass("noy-scroll");
    }, 500);
    // console.log('site', self.curSite)

    let top_nav = _.get(self.$route, "query.top_nav", 1);
    let type = _.get(self.$route, "query.type", "card");
    if (type) {
      self.payMethod = type;
    }
    self.$parent.closeLoading();
    // if (top_nav == 0) {
    //   self.$refs.pageTop.hide();
    // } else {
    //   self.$refs.pageTop.setPageTitle(self.$t("paypal_checkout"));
    // }
    self.payBtnTxt = self.$t("m_buy_now"); //pay_now
    self.logos = helper.membershipPhoto();
    // self.paymentTotalStr = self
    //   .$t("uname_select_method")
    //   .replace("[first_name]", _.get(self.userInfo, "first_name"));
    self.initData();
    // let tmpUser = self.$storage.get("tmp-user");

    let token = self.$storage.get("token");
    if (!token) {
      let tmpUser = self.$storage.get("tmp-user");

      if (tmpUser && tmpUser.token) {
        token = tmpUser.token;
        self.$store.state.token = tmpUser.token;
      }
    }

    if (token) {
      self.$layer.loading();

      self.$api
        .getClientMe(self.$store.state)
        .then((rs) => {
          self.$layer.closeAll("loading");
          self.client = _.get(rs, "client");
        })
        .catch((err) => {
          self.$layer.closeAll("loading");

          console.log(["me error", err]);
        });
    } else {
      self.$refs.registerForm.showModal();
    }

    // console.log(self.selectedPlan);
    setTimeout(function () {
      //   self.cardFocus = true;
      //   $("#cardNumberInput2").focus();
      self.$refs.creditCardCom.setInputFocus();
    }, 800);
  },
  methods: {
    goback() {
      this.$router.back();
    },
    setPaymentDesc(text_key) {
      let self = this;

      //<term>Terms</term>
      let text = self
        .$t(text_key)
        .replace(
          "<term>",
          `<a href="/app/terms" class="text-7e underline" target="_blank">`
        )
        .replace("</term>", "</a>");

      return text;
    },
    payMethodChange(type) {
      // console.log('payMethodChange')
      this.payMethod = type;
      this.initData();
    },
    uploadReceipt() {
      let self = this;
      console.log("uploadReceipt", self.extras);
      self.$storage.set("payment_extras", self.extras);
      self.$router.push({
        name: "payment-receipt",
      });
    },
    formatPrice2(price_val) {
      return helper.currenyPriceFormat(
        price_val,
        _.get(this.selectedPlan, "currency.show_decimals")
      );
    },
    getUserData(data) {
      let self = this;
      //   console.log("self.extras", self.extras);
      self.$storage.set("tmp-user", data);

      self.setClientParams(data);
      //   console.log("self.extras 2", self.extras);
      self.$refs.registerForm.hideModal();
    },
    setClientParams(data) {
      let self = this;
      self.client = {
        client_id: _.get(data, "id"),
        first_name: _.get(data, "name"),
        city: _.get(data, "city"),
      };

      if (self.extras) {
        self.extras.client_id = _.get(data, "id");
      } else {
        self.extras = { client_id: _.get(data, "id") };
      }

      if (self.membership) {
        self.membership.client_id = _.get(data, "id");
      }
      self.canSubmit = true;
      self.initStyle();
      //   console.log("self.extras=", self.extras);
    },
    openApp() {
      location.href = "http://r80k.app.link";
    },
    payNow() {
      let self = this;
      if (!self.canSubmit) {
        // console.log("canSubmit 0");
        return false;
      }
      if (!self.extras.client_id) {
        self.canSubmit = false;
        self.initStyle();
        // console.log("canSubmit 1");
        return false;
      }
      if (self.payMethod != "card") {
        self.$layer.msg("invalid payment method!");
        return false;
      }
      self.doPayStripe();
    },
    doPayStripe() {
      let self = this;
      // let repeat = self.getRepeat();
      // console.log(repeat);
      // return false;
      //todo check field
      //   console.log('stripe', self.card)
      if (!self.card.number) {
        self.$layer.msg(self.$t("payment_3col_card_txt"));
        return false;
      }
      //   if (!self.card.holderName) {
      //     self.$layer.msg(self.$t("validate_cardname_empty"));
      //     return false;
      //   }
      // || !self.card.expiryMonth || !self.card.expiryYear
      //!self.expiryDate
      if (!self.card.expiryMonth || !self.card.expiryYear) {
        self.$layer.msg(self.$t("payment_3col_expiry_txt"));
        return false;
      }
      let now = self.$moment(new Date());
      let expDate = self.$moment(
        `${self.card.expiryYear}-${self.card.expiryMonth}`
      );

      if (expDate.diff(now) < 0) {
        self.$layer.msg(self.$t("payment_3col_expiry_txt"));
        return false;
      }
      //return false;

      if (!self.card.cvv) {
        self.$layer.msg(self.$t("payment_3col_security_txt"));
        return false;
      }
      let needEmail = false;
      let mailReg = /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/;
      if (needEmail && !mailReg.test(self.card.email)) {
        self.$layer.msg(self.$t("val_email_invalid"));
        return false;
      }
      // [self.card.expiryYear, self.card.expiryMonth] = expDate.format('YYYY-MM').split('-');

      // console.log(self.card);
      // return false;

      let data = {
        provider: "stripe",
        card: self.card,
        address: self.address,
        order: self.order,
        repeat: null,
        membership: self.membership,
        product_id: null,
        paymentFlow: self.paymentFlow,
        extras: self.extras,
        test: self.isTest,
        token: null,
      };
      if (
        self.extras.productType == "upgrade" ||
        self.extras.productType == "lsm_upgrade" ||
        self.extras.productType == "speed_dating_membership"
      ) {
        data.repeat = self.getRepeat();
        data.extras.auto_renew = 1;
        // console.log("stripe", data);
        if (!data.repeat.interval) {
          self.$layer.msg("Incorrect interval");
          return false;
        }
      }
      // console.log(data);
      // return false;
      //console.log(JSON.stringify(data));
      self.requestUniversalPay(data);
    },
    requestUniversalPay(data) {
      //console.log('requestUniversalPay');
      let self = this;
      if (!self.requestPayUrl) {
        return false;
      }

      // console.log(self.requestPayUrl, JSON.stringify(data));

      // return false;

      let lid = self.$layer.loading();
      //console.log('。。。。。。');
      self.axios
        .post(self.requestPayUrl, JSON.stringify(data))
        .then((rs) => {
          //console.log(rs);
          self.checkCreditCardResult(_.get(rs.data, "transaction"));
        })
        .catch((err) => {
          console.log(["pay err", err]);
          self.$layer.closeAll("loading");
          self.$layer.msg(_.get(err.response, "data.error.body"));
        });
    },
    checkCreditCardResult(data) {
      let self = this;
      //success

      if (_.get(data, "status") == "Sale") {
        self.goToSuccess();
      } else {
        self.$layer.closeAll("loading");
        try {
          window.webkit.messageHandlers.jsHandler.postMessage("payment_failed");
        } catch (err) {
          self.$layer.msg(_.get(data, "message", "Failed, please check."));
          self.$router.push({
            name: "payment-declined",
          });
          // if (_.has(window, "Bridge")) {
          //   window.Bridge.finish("close");
          // } else {
          //   history.back();
          // }
        }
        // if (_.has(window, "webkit.messageHandlers.jsHandler")) {
        //   window.webkit.messageHandlers.jsHandler.postMessage("payment_failed");
        // }
        // else if (_.has(window, "Bridge")) {
        //     window.Bridge.finish("close");
        // }
      }
    },
    goToSuccess() {
      let self = this;
      let productType = _.get(self.selectedPlan, "productType", "upgrade");
      if (productType == "upgrade") {
        setTimeout(function () {
          self.$layer.closeAll("loading");
          //${self.curCity}/
          let cityShort = helper.cityShortName(self.curCity);
          location.href = `${location.origin}/app/payment/success-${cityShort}-${self.styleType}-${self.extras.month}m`;
          //   self.$router.push({
          //     name: "upgrade-congrats",
          //   });
        }, 800);
      } else {
        self.$layer.closeAll("loading");
        self.$router.push({
          name: "payment-success",
        });
      }
      //   location.href = _.get(self.paymentFlow, "successUrl");
      //payment-success
    },
    initData() {
      let self = this;
      self.selectedPlan = self.$storage.getPaymentSelectedPlan();

      if (!self.selectedPlan) {
        // console.log("no selectedPlan");
        self.canSubmit = false;
        self.errorMsg = "Missing payment data!";
        return false;
      }
      //
      //   console.log("self.selectedPlan", self.selectedPlan);
      //${self.curCity}/
      self.web_base_url = `${location.origin}/app/`;

      let productType = _.get(self.selectedPlan, "productType", "upgrade");
      self.extras = {
        productType: productType,
        currency_id: _.get(self.selectedPlan, "currency.currency_id", 1),
        client_id: _.get(
          self.selectedPlan,
          "client_id",
          _.get(self.userInfo, "client_id", 0)
        ),
        auto_renew: 0,
        platform: "web",
        coupon: _.get(self.selectedPlan, "coupon"),
      };
      switch (productType) {
        case "date_credit":
          self.initDateCreditData();
          break;
        case "event_ticket":
          self.initEventTicketData();
          break;
        case "lsm_upgrade":
        case "upgrade":
          self.initUpgradeData();
          break;
        case "speed_dating_membership":
          self.initSpeedDatingMembershipData();
          break;
        case "event":
          self.initEventData();
          break;
        case "extend_chat":
          self.initExtendData();
          break;
        case "lovestruck_user":
          self.initLovestruckData();
          break;
      }
      self.canSubmit = true;
      self.initStyle();

      //   console.log(
      //     "order",
      //     self.order,
      //     "extra",
      //     self.extras,
      //     "membership",
      //     self.membership
      //   );
    },
    initExtendData() {
      let self = this;
      self.styleType = "extend_chat";

      self.orderCard = {
        amount:
          _.get(self.selectedPlan, "currency.symbol", "") +
          _.get(self.selectedPlan, "product.price", 0),
        amount_sub: `${_.get(
          self.selectedPlan,
          "product.num_extends",
          0
        )} Credits`,
      };

      self.requestPayUrl = `/api/${self.country}/payment/crmExtendChatPay`;

      self.order = {
        title: "Lovestruck Extend Credits",
        description: `${_.get(
          self.selectedPlan,
          "product.num_extends",
          0
        )} Credits`,
        currency:
          self.currency_map[
            _.get(self.selectedPlan, "currency.currency_iso", "")
          ],
        amount: _.get(self.selectedPlan, "product.price", 0),
        divisionAmount: null,
      };

      self.extras.sourceId = _.get(
        self.selectedPlan,
        "product.extend_price_id",
        ""
      );
      self.extras.num = 1;
      self.extras.price = _.get(self.selectedPlan, "product.price", 0);

      self.paymentFlow = {
        notifyUrl: `${self.api_base_url}${self.country}/payment/crmExtendChatPayNotify`,
        successUrl: `${self.web_base_url}payment/paySuccess`,
        failUrl: `${self.web_base_url}payment/failed`,
      };
      self.membership = null;
    },
    initLovestruckData() {
      let self = this;
      self.styleType = "lovestruck_user";

      self.orderCard = {
        amount:
          _.get(self.selectedPlan, "currency.symbol", "") +
          _.get(self.selectedPlan, "product.price", 0),
        amount_sub: `${_.get(
          self.selectedPlan,
          "product.num_lovestrucks",
          0
        )} Credits`,
      };

      self.requestPayUrl = `/api/${self.country}/payment/crmLovestruckUserPay`;

      self.order = {
        title: "Lovestruck Lovestruck Credits",
        description: `${_.get(
          self.selectedPlan,
          "product.num_lovestrucks",
          0
        )} Credits`,
        currency:
          self.currency_map[
            _.get(self.selectedPlan, "currency.currency_iso", "")
          ],
        amount: _.get(self.selectedPlan, "product.price", 0),
        divisionAmount: null,
      };

      self.extras.sourceId = _.get(
        self.selectedPlan,
        "product.lovestruck_price_id",
        ""
      );
      self.extras.num = 1;
      self.extras.price = _.get(self.selectedPlan, "product.price", 0);

      self.paymentFlow = {
        notifyUrl: `${self.api_base_url}${self.country}/payment/crmLovestruckUserNotify`,
        successUrl: `${self.web_base_url}payment/success`,
        failUrl: `${self.web_base_url}payment/failed`,
      };
      self.membership = null;
    },
    initEventData() {
      let self = this;
      self.styleType = "event";

      let price = _.get(self.selectedPlan, `product.price`, 0);
      let tnum = _.get(self.selectedPlan, `product.num`, 0);

      let amount = price * tnum;
      self.paymentDesc = self.$t("payment_desc2");

      self.orderCard = {
        amount: _.get(self.selectedPlan, "currency.symbol", "") + amount,
        amount_sub: `${_.get(self.selectedPlan, "product.description", "")}`,
      };

      self.requestPayUrl = `/api/${self.country}/payment/crmEventPay`;

      self.order = {
        title: _.get(self.selectedPlan, "product.description", ""),
        description: `${_.get(
          self.selectedPlan,
          "product.description",
          ""
        )} ${_.get(self.selectedPlan, `product.num`, 0)} ticket(s)`,
        currency:
          self.currency_map[
            _.get(self.selectedPlan, "currency.currency_iso", "")
          ],
        amount: amount,
        divisionAmount: null,
      };

      self.extras.sourceId = _.get(self.selectedPlan, "sourceId", 0);
      self.extras.num = tnum;
      self.extras.price = price;
      self.extras.client_order_id = _.get(
        self.selectedPlan,
        "client_order_id",
        0
      );

      self.paymentFlow = {
        notifyUrl: `${self.api_base_url}${self.country}/payment/crmEventPayNotify`,
        successUrl: `${self.web_base_url}payment/success`,
        failUrl: `${self.web_base_url}payment/failed`,
      };
      self.membership = {
        client_id: self.extras.client_id,
      };
    },
    initEventTicketData() {
      let self = this;
      self.paymentDesc = self.$t("payment_desc2");
      self.styleType = "event_ticket";
      //   console.log(self.currency_map);

      self.orderCard = {
        amount:
          _.get(self.selectedPlan, "currency.symbol", "") +
          _.get(self.selectedPlan, "product.price", 0),
        amount_sub: `${_.get(
          self.selectedPlan,
          "product.num_event_tickets",
          0
        )} Tickets`,
      };

      self.requestPayUrl = `/api/${self.country}/payment/crmEventTicketPay`;

      self.order = {
        title: "Lovestruck Event Tickets",
        description: `${_.get(
          self.selectedPlan,
          "product.num_event_tickets",
          0
        )} Tickets`,
        currency:
          self.currency_map[
            _.get(self.selectedPlan, "currency.currency_iso", "")
          ],
        amount: _.get(self.selectedPlan, "product.price", 0),
        divisionAmount: null,
      };

      self.extras.sourceId = _.get(
        self.selectedPlan,
        "product.event_ticket_price_id",
        ""
      );
      self.extras.num = 1;
      self.extras.price = _.get(self.selectedPlan, "product.price", 0);

      self.paymentFlow = {
        notifyUrl: `${self.api_base_url}${self.country}/payment/crmEventTicketPayNotify`,
        successUrl: `${self.web_base_url}payment/paySuccess`,
        failUrl: `${self.web_base_url}payment/failed`,
      };
      self.membership = null;
    },
    initSpeedDatingMembershipData() {
      let self = this;
      self.styleType = `speed_${_.get(
        self.selectedPlan,
        "plan_name",
        "basic"
      )}`;
      let plan_month = _.get(self.selectedPlan, "plan_month", 6);
      let amount = _.get(
        self.selectedPlan,
        `product.price.${plan_month}_month`,
        0
      );
      // console.log("self.selectedPlan", self.selectedPlan);
      // let monthStr =
      //   plan_month > 1 ? self.$t("month_s") : self.$t("payment_3col_month");

      self.paymentDesc = self.setPaymentDesc("payment_desc");
      self.orderCard = {
        amount: _.get(self.selectedPlan, "currency.symbol", "") + amount,
        amount_per_month:
          _.get(self.selectedPlan, "currency.symbol", "") +
          parseInt(amount / plan_month) +
          `/` +
          self.$t("payment_3col_month"),
        amount_sub: `${_.get(
          self.selectedPlan,
          "product.description",
          ""
        )} ${plan_month} ${self.$t("n_month")}`, //${self.$t("num_unit")}
      };
      self.requestPayUrl = `/api/${self.country}/payment/crmMembershipPay`;

      self.order = {
        title: _.get(self.selectedPlan, "product.description", ""),
        description:
          _.get(self.selectedPlan, "product.description", "") +
          "-" +
          _.get(self.selectedPlan, "plan_month", "") +
          self.$t("n_month"),
        currency:
          self.currency_map[
            _.get(self.selectedPlan, "currency.currency_iso", "")
          ],
        amount: amount,
        divisionAmount: null,
      };

      self.extras.sourceId = _.get(
        self.selectedPlan,
        "product.price.membership_level_speed_dating_price_id",
        0
      );
      self.extras.num = 1;
      //   self.extras.auto_renew = 1;
      self.extras.price = self.order.amount;
      self.extras.month = self.selectedPlan.plan_month;
      self.extras.client_order_id = _.get(
        self.selectedPlan,
        "client_order_id",
        0
      );

      self.paymentFlow = {
        notifyUrl: `${self.api_base_url}${self.country}/payment/crmMembershipPayNotify`,
        successUrl: `${self.web_base_url}payment/paySuccess`,
        failUrl: `${self.web_base_url}payment/failed`,
      };
      //   self.membership = {
      //     client_id: _.get(self.extras, "client_id"),
      //     membership_level_price_id: self.extras.sourceId,
      //     month: self.extras.month,
      //   };
    },
    initUpgradeData() {
      let self = this;

      //   self.payBtnTxt = self.$t("subscribe_txt");
      self.styleType = _.get(self.selectedPlan, "plan_name", "gold");
      //   console.log("self.styleType=", self.styleType);
      //   self.styleType = 'diamond'
      let plan_month = _.get(self.selectedPlan, "plan_month", 6);
      let s_amount = _.get(
        self.selectedPlan,
        `product.price_row.${plan_month}_month`,
        0
      );
      //   console.log('product',_.get(
      //     self.selectedPlan,
      //     `product.price_row`
      //   ))
      let coupon = 0;
      let amountSub = "";
      let amount = s_amount;
      if (self.extras.coupon == "free_50%_off") {
        //   let f_amount = Math.floor(amount/2)
        let f_amount = _.get(
          self.selectedPlan,
          `product.price_50off_${plan_month}month`,
          0
        );
        coupon = s_amount - f_amount;
        amount = f_amount;
        //   coupon_text = 'Discount 50%';

        amountSub = `<div>${self.$t("discount_50")}</div> <div>-${_.get(
          self.selectedPlan,
          "currency.symbol",
          ""
        )}${self.formatPrice2(coupon)}</div>`;
      } else if (self.extras.coupon == "free_100%_off") {
        let f_amount = 0;
        coupon = s_amount - f_amount;
        amount = f_amount;
        //   coupon_text = 'Discount 50%';

        amountSub = `<div>${self.$t("discount_100")}</div> <div>-${_.get(
          self.selectedPlan,
          "currency.symbol",
          ""
        )}${self.formatPrice2(coupon)}</div>`;
      } else if (_.get(self.selectedPlan, "discount_price", 0) > 0) {
        console.log(
          "use discount_price",
          _.get(self.selectedPlan, "discount_price", 0)
        );
        amount = _.get(self.selectedPlan, "discount_price", 0);
      } else {
        amountSub = `${_.get(
          self.selectedPlan,
          "product.description",
          ""
        )} ${plan_month} ${self.$t("n_month")}`;
      }
      //   console.log("initUpgradeData", self.formatPrice2(amount));
      //   amount = self.formatPrice(amount);
      // console.log("self.selectedPlan", self.selectedPlan);
      // let monthStr =
      //   plan_month > 1 ? self.$t("month_s") : self.$t("payment_3col_month");
      if (coupon > 0) {
        self.paymentDesc = self
          .setPaymentDesc("payment_desc_promo")
          .replace(
            "[regular_price]",
            `<b>${_.get(
              self.selectedPlan,
              "currency.symbol",
              ""
            )}${self.formatPrice2(s_amount)}</b>`
          );
      } else {
        self.paymentDesc = self.setPaymentDesc("payment_desc");
      }

      // let original_price = _.get(self.selectedPlan, "original_price", 0);
      // let save_price = original_price - amount;
      // if(original_price){
      //     self.newOrderCard = true

      let original_price = _.get(self.selectedPlan, "original_price", 0);
      //   original_price = 15999;
      let save_price = original_price - amount;
      if (original_price && save_price > 0) {
        self.showDiscountLine = true;
      }

      let month = _.get(self.selectedPlan, "plan_month", "");
      // month = 1;
      let monthText = month > 1 ? self.$t("n_month") : self.$t("1_month");
      let symbol = _.get(self.selectedPlan, "currency.symbol", "");

      let member_for = self
        .$t("membership_for_month")
        .replace("[x]", month)
        .replace("[month]", monthText)
        .replace("[amount]", `${symbol}${self.formatPrice2(amount)}`);

      let renew_same =
        month > 1
          ? self
              .$t("renew_same_price")
              .replace("[x]", month)
              .replace("[month]", monthText)
          : self.$t("renew_with_month");

      let title = member_for;

      if (self.payMethod == "card") {
        title = `${title}<br/>${renew_same}`;
      }

      // console.log(self.payMethod, title)

      self.orderCard = {
        title: title,
        original_price: `${symbol}${self.formatPrice2(original_price)}`,
        save: `${symbol}${self.formatPrice2(save_price)} (${_.get(
          self.selectedPlan,
          "discount",
          0
        )}%)`,
        amount:
          _.get(self.selectedPlan, "currency.symbol", "") +
          self.formatPrice2(amount),
        amount_per_month:
          _.get(self.selectedPlan, "currency.symbol", "") +
          self.formatPrice2(
            _.get(self.selectedPlan, `product.price_pre_${plan_month}month`)
          ) +
          `/` +
          self.$t("payment_3col_month"),
        amount_sub: amountSub,
      };
      //   console.log("orderCard", self.orderCard);
      self.requestPayUrl = `/api/${self.country}/payment/crmMembershipPay`;

      self.order = {
        title: _.get(self.selectedPlan, "product.description", ""),
        description:
          _.get(self.selectedPlan, "product.description", "") +
          "-" +
          _.get(self.selectedPlan, "plan_month", "") +
          self.$t("n_month"),
        currency:
          self.currency_map[
            _.get(self.selectedPlan, "currency.currency_iso", "")
          ],
        amount: s_amount,
        divisionAmount: null,
      };

      self.extras.sourceId = _.get(
        self.selectedPlan,
        "product.price_row.membership_level_price_id",
        0
      );
      self.extras.num = 1;
      self.extras.price = self.order.amount;
      self.extras.month = self.selectedPlan.plan_month;
      self.extras.client_order_id = _.get(
        self.selectedPlan,
        "client_order_id",
        0
      );

      self.paymentFlow = {
        notifyUrl: `${self.api_base_url}${self.country}/payment/crmMembershipPayNotify`,
        successUrl: `${self.web_base_url}payment/paySuccess`,
        failUrl: `${self.web_base_url}payment/failed`,
      };
      self.membership = {
        client_id: _.get(self.extras, "client_id"),
        membership_level_price_id: self.extras.sourceId,
        month: self.extras.month,
      };

      //   let cityShort = helper.cityShortName(self.curCity)

      //       let successUrl = `${location.origin}/app/payment/success-${cityShort}-${self.styleType}-${self.extras.month}m`;
      //   console.log('successUrl=', successUrl)
    },
    initDateCreditData() {
      let self = this;
      self.paymentDesc = self.$t("payment_desc2");
      self.styleType = "date_credit";

      self.orderCard = {
        amount:
          _.get(self.selectedPlan, "currency.symbol", "") +
          _.get(self.selectedPlan, "product.price", 0),
        amount_sub: `${_.get(
          self.selectedPlan,
          "product.num_date_credits",
          0
        )} ${self.$t("reservation_credits")}`,
      };

      self.requestPayUrl = `/api/${self.country}/payment/crmDateCreditPay`;

      self.order = {
        title: "Lovestruck Date Credits",
        description: `${_.get(
          self.selectedPlan,
          "product.num_date_credits",
          0
        )} ${self.$t("Date_credits")}`,
        currency:
          self.currency_map[
            _.get(self.selectedPlan, "currency.currency_iso", "")
          ],
        amount: _.get(self.selectedPlan, "product.price", 0),
        divisionAmount: null,
      };

      self.extras.sourceId = _.get(
        self.selectedPlan,
        "product.date_credit_price_id",
        ""
      );
      self.extras.num = 1;
      self.extras.price = _.get(self.selectedPlan, "product.price", 0);

      self.paymentFlow = {
        notifyUrl: `${self.api_base_url}${self.country}/payment/crmDateCreditPayNotify`,
        successUrl: `${self.web_base_url}payment/paySuccess`,
        failUrl: `${self.web_base_url}payment/failed`,
      };
      self.membership = null;
    },
    initStyle() {
      let self = this;
      let disabled = "";
      //   console.log("self.extras", self.extras, self.canSubmit);
      if (!self.extras.client_id) {
        self.canSubmit = false;
      }

      if (!self.canSubmit) {
        disabled = "disabled";
      }
      // console.log("initStyle", self.styleType);

      switch (self.styleType) {
        case "plus-bak":
          self.style = {
            cardOn: "text-1d fw-600", //border-ls-red
            payBtn: `btn-ee1 ${disabled}`,
          };
          self.checkLableClass = "red";
          self.btnVariant = `ee1`;
          self.plan_logo = _.get(
            helper.membershipLogoTextPhoto(),
            self.styleType
          );
          self.plan_logo_h = 30;
          break;
        case "regular-bak":
        case "premium-bak":
        case "gold-bak":
        case "starter-bak":
          self.style = {
            cardOn: "text-1d fw-600", //border-ls-red
            payBtn: `btn-ee1 ${disabled}`,
          };
          self.checkLableClass = "ee1";
          self.btnVariant = `ee1`;
          self.plan_logo = _.get(
            helper.membershipLogoTextPhoto(),
            self.styleType
          );
          self.plan_logo_h = 30;
          break;

        case "diamond-bak":
          self.style = {
            cardOn: "text-1d fw-600", //border-ls-red
            payBtn: `btn-ee1 ${disabled}`,
          };
          self.checkLableClass = "dia";
          self.btnVariant = `ee1`;
          //   let logos = helper.membershipPhoto()
          //   self.plan_logo = self.logos["basic"];
          self.plan_logo = _.get(
            helper.membershipLogoTextPhoto(),
            self.styleType
          );
          self.plan_logo_h = 30;
          break;

        case "speed_basic":
          self.style = {
            cardOn: "text-1d fw-600", //border-ls-red
            payBtn: `bg-ee1 ${disabled}`,
          };
          self.plan_logo = _.get(helper.lsLogos(), "basic");
          break;
        case "speed_gold":
          self.style = {
            cardOn: "text-1d fw-600", //border-ls-y
            payBtn: `btn-ee1 ${disabled}`,
          };
          self.plan_logo = _.get(helper.lsLogos(), "gold");
          break;
        // case "plus":
        case "basic":
          self.style = {
            cardOn: "text-ls-red", //border-ls-red
            payBtn: `bg-ls-red text-white ${disabled}`,
          };
          //   self.plan_logo = self.logos["basic"];
          self.plan_logo =
            "https://cdn-cf2.lovestruck.com/upload-s/gen/img/ls-app-6.0/payment/LS_icon_small.png";
          self.plan_logo_h = 30;
          break;
        // case "gold":
        //   self.style = {
        //     cardOn: "text-gold",//border-ls-y
        //     payBtn: `bg-gold text-dred ${disabled}`,
        //   };
        // //   self.plan_logo = self.logos["gold"];
        //   self.plan_logo = 'https://cdn-cf2.lovestruck.com/upload-s/gen/img/ls-app-6.0/payment/LS_icon_small.png'
        //   self.plan_logo_h = 30;
        //   break;
        case "platinum":
          self.style = {
            cardOn: "text-dark", //border-ls-b
            payBtn: `bg-platinum text-white ${disabled}`,
          };
          self.plan_logo = self.logos["platinum"];
          break;

        // case "diamond":
        //   self.style = {
        //     cardOn: "text-lsblue",//border-lsblue
        //     payBtn: `bg-diamond ${disabled}`,
        //   };
        //   self.plan_logo = self.logos["diamond"];
        //   break;

        case "event":
          self.style = {
            cardOn: "text-1d fw-600", //border-lsblue
            payBtn: `btn-ee1 ${disabled}`,
          };
          self.plan_logo = self.logos["diamond"];
          break;
        case "date_credit":
        case "event_ticket":
        case "extend_chat":
        case "lovestruck_user":
          self.style = {
            cardOn: "text-1d fw-600", //border-lsblue
            payBtn: `btn-ee1 ${disabled}`,
          };
          self.plan_logo =
            "https://cdn-cf2.lovestruck.com/upload-s/gen/img/ls_logo_gold.svg";
          break;
        default:
          self.style = {
            cardOn: "text-1d fw-600", //border-ls-red
            payBtn: `btn-ee1 ${disabled}`,
          };
          self.checkLableClass = "ee1";
          self.btnVariant = `ee1`;
          self.plan_logo = _.get(
            helper.membershipPhoto(),
            self.styleType,
            null
          );
          self.plan_logo_h = 40;
          break;
      }
    },
    receiveCard(data) {
      let self = this;
      //   console.log("card", data);
      self.card = data;
      self.address.email = _.get(data, "email");
    },
    getRepeat: function () {
      let self = this;
      let repeat = {
        interval: null,
        startDate: "",
        endDate: "",
        order: null,
      };

      if (_.get(self.extras, "productType") == "event") {
        return repeat;
      }
      // console.log("selectedPlan", self.selectedPlan);
      let pmonth = parseInt(_.get(self.selectedPlan, "plan_month"));
      switch (pmonth) {
        case 1:
          repeat.interval = "monthly";
          repeat.startDate = self
            .$moment()
            .add(1, "months")
            .format("YYYY-MM-DD");
          break;
        case 3:
          repeat.interval = "quarterly";
          repeat.startDate = self
            .$moment()
            .add(3, "months")
            .format("YYYY-MM-DD");
          break;
        case 6:
          repeat.interval = "half-yearly";
          repeat.startDate = self
            .$moment()
            .add(6, "months")
            .format("YYYY-MM-DD");
          break;
        case 12:
          repeat.interval = "yearly";
          repeat.startDate = self
            .$moment()
            .add(1, "years")
            .format("YYYY-MM-DD");
          break;
        default:
          return {
            interval: null,
            startDate: "",
            endDate: "",
            order: null,
          };
      }
      repeat.endDate = self
        .$moment(repeat.startDate)
        .add(12, "months")
        .format("YYYY-MM-DD");
      return repeat;
    },
  },
};
</script>
<style scoped>
.bankInfo {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 10px;
  color: #7e7e7e;
}
.bankInfo .item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.bankInfo .item:last-child {
  border-bottom: none;
}
.bankInfo .name,
.bankInfo .value {
  padding: 10px;
  width: 136px;
  font-size: 14px;
}
.bankInfo .dev-line {
  width: 1px;
  height: 63px;
  background: rgba(0, 0, 0, 0.125);
}
.bankInfo .value {
}
</style>