<template>
  <div class="bg-faf min-h100">
    <div class="page6-2 text-center px-3 position-relative">
      <div style="height: 5vh"></div>
      <div class="mb-5-">
        <b-img center width="240"
          src="https://cdn-cf2.lovestruck.com/upload/gen/img/ls-app-6.2/logos/Lovestruck-Ruby-Horizontal-Lockup-RGB3.png"></b-img>
      </div>
      <div style="height: 10vh"></div>
      <h5 class="text-center my-3 text-1d fw-700">
        {{ $t("enter_u_mobile") }}
      </h5>
      <div class="register_box position-relative mx-auto-">
        <div class="form-group text-center">
          <div class="">
            <button @click="showCountrySmsCode" v-html="selectCountryHtml" type="button"
              class="btn dropdown-toggle d-inline-block v6-2 icode_btn mr-1 fs-18"
              style="position: relative; top: -2px"></button>
            <!-- b-lxxxform-control d-inline-block rounded pl-2 bg-white 】 -->
            <b-input type="tel" class="phoneInput d-inline-block v6-2 l-space1" @keyup.enter="submitBtn"
              @input="checkInput" v-model="mobile" autocomplete="off" :placeholder="phoneNumberPlaceholder" maxlength="15"
              style="width: calc(100% - 170px)" id="phoneMobile" ref="phoneInputRef"></b-input>
          </div>
        </div>
        <div>
          <countrySmsCodeList :topx="topX" @selectCountry="selectCountry" ref="countrySmsCodeList" class="page6-2"
            style="height: 200px"></countrySmsCodeList>
        </div>
        <div class="text-right">
          <b-button pill variant="ee1" @click="submitBtn" :class="canSubmit ? '' : 'disabled'" class="o-btn lg"><i
              class="fa-light fa-arrow-right"></i></b-button>
        </div>
      </div>

      <!-- <div class="mb-4">
        <b-button pill variant="ee1" @click="goToSign" class="px-5 l-space2 text-uppercase"
          style="height: 53px; min-width: 240px">GET STARTED</b-button>
      </div> -->
      <div style="height: 5vh"></div>
      <p class="text-7e fs-12 mb-4" v-html="sign_term_privacy_cookie"></p>
      <div>
        <b-img center class="w-100"
          src="https://cdn-cf2.lovestruck.com/upload-m/gen/img/ls-app-6.2/home_main.png"></b-img>
      </div>
    </div>
    <!-- <div id="loading6"></div> -->
    <Loading6 ref="Loading6" />
  </div>
</template>
<script>
import countrySmsCodeList from "./common/countrySmsCodeList";
import helper from "../utils/helper";
import Loading6 from "./common/loading6.2.vue";
export default {
  name: "lsm-home",
  components: {
    Loading6,
    countrySmsCodeList
  },
  data() {
    return {
      topX: 60,
      icode: "",
      mobile: "",
      dob: "",
      selectCountryHtml: "",
      canSubmit: false,
      phoneNumberPlaceholder: this.$t("phone_number"),
      curSite: this.$store.getters.curSite,
      sign_term_privacy_cookie: "",
      utmParams: {
        utm_source: "",
        utm_medium: "",
        utm_campaign: "",
        utm_term: "",
        utm_content: "",
      },
      curSite: this.$store.getters.curSite,
    };
  },
  mounted() {
    let self = this;
    // self.$parent.startLottie('loading6', 'img/ls-app-6.2/animation/Lovestruck-Loading-Animation.lottie.json')
    setTimeout(() => {
      self.$refs.Loading6.show();
      //   self.$refs.Loading6.hide();
    }, 80);

    setTimeout(() => {
      // self.$refs.Loading6.show();
      self.$refs.Loading6.hide();
    }, 3000);
    self.langCode = self.$storage.get("cur-lang");
    // console.log(self.langCode, self.curSite)

    let lang = self.curSite.lang_default.replace("_", "-");
    if (lang.indexOf("en") > -1) {
      lang = "en";
    }

    self.icode = _.get(self.curSite, "dial_code", "852");
    self.selectCountryHtml = `<img src="${self.curSite.flag_url}" class="border-0"/> +${self.icode}`;


    // if(self.$route.name == 'home-lsm'){
    //     console.log('auto login')
    //     self.checkAutoLogin()
    // }
    // self.showLogin = _.get(self.$route.query, "token", null) ? false : true;
    self.utmParams.utm_source = _.get(self.$route.query, "utm_source");
    self.utmParams.utm_medium = _.get(self.$route.query, "utm_medium");
    self.utmParams.utm_campaign = _.get(self.$route.query, "utm_campaign");
    self.utmParams.utm_term = _.get(self.$route.query, "utm_term");
    self.utmParams.utm_content = _.get(self.$route.query, "utm_content");

    let current_city = self.$storage.get("ls_city");

    let partUrl = `/${current_city}/${lang}`;

    self.sign_term_privacy_cookie = self.$t("sign_term_privacy_cookie");
    // self.sign_term_privacy_cookie = 'By tapping Sign in, you agree with our <a-terms>Terms</a-terms>. Learn how we process your data in our <a-privacy>Privacy Policy</a-privacy> and <a-cookie>Cookies Policy</a-cookie>.';
    //https://www.lovestruck.com/hong-kong/en/terms/
    //https://www.lovestruck.com/hong-kong/en/privacy/
    //https://www.lovestruck.com/hong-kong/en/cookies/
    self.sign_term_privacy_cookie = self.sign_term_privacy_cookie
      .replace("[tap_name]", self.$t("web_lbl_nextsteps"))
      .replace(
        "<a-terms>",
        `<a href="${partUrl}/terms/" class="text-7e underline" target="_blank">`
      )
      .replace("</a-terms>", "</a>")
      .replace(
        "<a-privacy>",
        `<a href="${partUrl}/privacy/" class="text-7e underline" target="_blank">`
      )
      .replace("</a-privacy>", "</a>")
      .replace(
        "<a-cookie>",
        `<a href="${partUrl}/cookies/" class="text-7e underline" target="_blank">`
      )
      .replace("</a-cookie>", "</a>");
  },
  methods: {
    goToSign() {
      let self = this;
      self.$router.push({ name: "sign-in-2", query: self.utmParams });
    },
    checkInput() {
      let self = this;
      console.log("input..");
      if (self.mobile.length > 0) {
        self.canSubmit = true;
      } else {
        self.canSubmit = false;
      }
      //   if (helper.checkMobile(self.mobile)) {
      //   if (helper.checkPhoneMobile(self.icode, self.mobile)) {
      //     self.canSubmit = true;
      //   } else {
      //     self.canSubmit = false;
      //   }
    },
    submitBtn() {
      var self = this;
      if (!self.canSubmit) {
        console.log("can not send");
        return false;
      }

      let realMobile = self.mobile.replace(/[^\d]/g, "");

      if (!self.icode || !helper.checkMobile2(self.icode, realMobile)) {
        //console.log('mobile error');
        self.$layer.msg(self.$t("val_mobile_invaild"));

        return false;
      }
      //   self.goToSmsVerify();
      //   return false;
      self.mobile = realMobile;

      self.$layer.loading();

      self.$api
        .getAccountExist(
          `icode=${self.icode}&mobile=${self.mobile}`,
          self.$store.state
        )
        .then((rs) => {
          if (_.get(rs, "is_exist")) {
            self.requestWithPhone();
          } else {
            self.registerClient();

          }
        })


    },
    registerClient() {
      let self = this;

      let fdata = new FormData();
      fdata.append("icode", self.icode);
      fdata.append("mobile", self.mobile);

      fdata.append("utm_source", self.utmParams.utm_source);
      fdata.append("utm_medium", self.utmParams.utm_medium);
      fdata.append("utm_campaign", self.utmParams.utm_campaign);
      fdata.append("utm_term", self.utmParams.utm_term);
      fdata.append("utm_content", self.utmParams.utm_content);

      self.$api.postRegister(fdata, self.$store.state)
        .then(response => {
          console.log(response)
          self.$layer.closeAll("loading");
          if (_.has(response, "client_id")) {
            helper.setLoginData(response);

            self.$store.commit(
              "SET_TOKEN",
              _.get(response, "access_token", "")
            );
            self.$store.commit("SET_CUR_LANG", {
              curlang: _.get(response, "lang_code", "en_US"),
            });

            let jump = helper.parseFirstLoadPage(response);

            if (jump.type == "link") {
              // console.log(url)
              if (typeof location != "undefined") {
                location.href = jump.route_name;
              }
              return false;
            }

            self.$router.push({
              name: jump.route_name,
              params: jump.params ? jump.params : {},
              query: jump.query ? jump.query : {},
            });




          } else {
            self.requestWithPhone();

          }
        }).catch((error) => {
          self.$layer.closeAll("loading");
          let message = _.get(error, "response.data.error.body");
          console.log(message);
          // self.alertLimit(
          //   message,
          //   null
          // );
          self.requestWithPhone();
        })
    },
    requestWithPhone() {
      let self = this;

      let fdata = new FormData();
      fdata.append("mobile_international_code", self.icode);
      fdata.append("mobile_phone_number", self.mobile);

      fdata.append("utm_source", self.utmParams.utm_source);
      fdata.append("utm_medium", self.utmParams.utm_medium);
      fdata.append("utm_campaign", self.utmParams.utm_campaign);
      fdata.append("utm_term", self.utmParams.utm_term);
      fdata.append("utm_content", self.utmParams.utm_content);

      self.$api
        .postRequestWithPhone(fdata, self.$store.state)
        .then((response) => {
          console.log(response);
          self.$layer.closeAll("loading");

          if (response.hasOwnProperty("success") && !response.success) {
            if (typeof response.output == "string") {
              let message = JSON.parse(response.output);
              // console.log("message", message);
              self.alertLimit(
                _.get(message, "error.body"),
                _.get(message, "error.header")
              );
            } else {
              self.alertLimit(
                _.get(response, "output.error.body"),
                _.get(response, "output.error.header")
              );
            }

            // self.$layer.msg(_.get(response, "output"));
            return false;
          } else {
            self.goToSmsVerify();
          }
        })
        .catch((error) => {
          self.$layer.closeAll("loading");
          // console.log(["sms error", error]);
          if (_.has(error, "response.data")) {
            let output = _.get(error, "response.data.output");
            if (output == "string") {
              let message = JSON.parse(output);
              // console.log("message", message);
              self.alertLimit(
                _.get(message, "error.body"),
                _.get(message, "error.header")
              );
            } else {
              self.alertLimit(
                _.get(output, "error.body"),
                _.get(output, "error.header")
              );
            }
            // self.alertLimit(_.get(error, "response.data.output.error.body"))
            // self.$layer.msg(_.get(error, "response.data.output.error.body"));
          } else if (
            _.has(error, "message") &&
            _.get(error, "message").indexOf("timeout") > -1
          ) {
            // self.step = 2;
            // $("#icode").focus();
            // self.ls_timer = setInterval(() => {
            //   self.dTime();
            // }, 1000);
            self.goToSmsVerify();
          }
        });
    },
    goToSmsVerify() {
      let self = this;

      self.$storage.set("signIn", {
        i: self.icode,
        m: self.mobile,
      });
      self.$router.push({ name: "sms-verify" });
    },
    showCountrySmsCode() {
      this.$refs.countrySmsCodeList.showList();
    },
    selectCountry(data) {
      this.icode = data.dial_code;
      //   console.log("daat", data);
      //   this.selectCountryHtml = `${data.emoji} +${data.dial_code}`;
      this.selectCountryHtml = `<img src="${data.flag_url}" class="border-0"/> +${data.dial_code}`;
      //   this.selectCountryHtml = `<img src="${data.flag_url}" class="border-0"/> +${data.dial_code}`;
    },
    mobileFormat(phone) {
      return /^[0-9]{6,15}$/.test(phone);
    },
  },
};
</script>
