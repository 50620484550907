<template>
  <!-- v-if="auto || isShow" -->
  <div v-if="isShow" class="loading6-2 bg-faf">
    <!-- <div id="loading6"></div> -->
    <div class="content">
      <img src="https://cdn-cf2.lovestruck.com/upload/gen/img/ls-app-6.2/animation/Lovestruck-Loading-Animation-356px.gif"
        class="w-100" />
    </div>
    <!-- <video src="https://cdn-cf2.lovestruck.com/upload/gen/img/ls-app-6.2/logos/Logo-Animation-min.mp4"
      poster="https://cdn-cf2.lovestruck.com/upload/gen/img/ls-app-6.2/logos/Lovestruck-Ruby-Horizontal-Lockup-RGB.png"
      type="video/mp4" autoplay loop playsinline x5-video-player-type="h5-page" id="loading6-2-video"></video> -->
  </div>
</template>
<script>
export default {
  name: "loading-6.2",
  props: {
    auto: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isShow: false,
    };
  },
  mounted() {
    let self = this;
    if (self.auto) {
      //   self.canplaythrough((d) => {
      //     console.log("canplaythrough", d);
      //     document.getElementById("loading6-2-video").play();
      //     setTimeout(() => {
      //       self.hide();
      //     }, 5000);
      //   });
    }
  },
  methods: {
    canplaythrough(callback) {
      document
        .getElementById("loading6-2-video")
        .addEventListener("canplaythrough", callback);
    },
    show() {
      $(".loading6-2").css({ opacity: 1 }).show();
      this.isShow = true;
      // this.$parent.$parent.startLottie('loading6', 'img/ls-app-6.2/animation/Lovestruck-Loading-Animation.lottie.json')
    },
    hide() {
      $(".loading6-2").animate({ opacity: 0 }, 1500);
      setTimeout(() => {
        $(".loading6-2").hide();
        this.isShow = false;
      }, 1300);
    },
  },
};
</script>
<style>
.loading6-2 {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.loading6-2 .content {
  position: absolute;
  max-width: 160px;
  width: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
