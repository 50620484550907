<template>
  <div class="bg-faf text-1d">
    <topName ref="topNav" :showMobileTop="false" />
    <div class="page_container hideMobileTop">
      <div v-if="currentData" class="page6-2">
        <div class="bg-white px-3">
          <div v-if="type == 'cb'" class="py-4 px-3">
            <h4 class="fw-600 mb-3 ff-prata">{{ hiText }}</h4>

            <p class="mb-3" v-html="$t('welcome_ls_text')"></p>

            <p class="mb-2">{{ $t("we_appreciate_desc") }}</p>

            <p class="mb-4">
              <b>{{ $t("ready_date_journey") }}</b>
              <i class="lsicon in_love_icon lsicon-24" style="top: 6px"></i>
              <i class="lsicon celebrate_icon lsicon-24" style="top: 6px"></i>
            </p>

            <h3 class="mb-3 fw-600 ff-prata">
              {{ $t("will_call_you") }}
            </h3>
            <div class="mb-3">
              <b-img
                class="w-100"
                src="https://cdn-cf2.lovestruck.com/upload-m/gen/img/ls_com_black/calling_mobile.jpg"
              ></b-img>
            </div>
            <p class="mb-3">{{ $t("call_to_start_membership") }}</p>
            <p class="mb-3">
              <b>{{ $t("dont_worry_for_time") }}</b>
            </p>
            <p v-html="callYouOn"></p>
            <p class="mb-3">{{ $t("usually_48_hours") }}</p>

            <p class="mb-2">{{ $t("thx_choose_ls") }}</p>

            <p class="mb-3">
              {{ $t("we_forward_speak") }}
              <i class="lsicon smile_icon lsicon-24" style="top: 6px"></i>
            </p>

            <!-- <p>If you missed our call, you can always contact us here:</p>

            <div v-if="currentData" class="fw-600">
              <a
                rel="nofollow"
                :href="`tel:${currentData.store.cs_phone_number}`"
                class="mr-1"
              >
                <img
                  src="https://cdn-cf2.lovestruck.com/upload-m/gen/img/ls_com_black/call_icon_white.png"
                  height="20"
                />
              </a>
              <a rel="nofollow" href="javascript:;" class="mr-1">
                <img
                  src="https://cdn-cf2.lovestruck.com/upload-m/gen/img/ls_com_black/whatsapp_icon_white.png.png"
                  height="20"
                />
              </a>
              <a
                rel="nofollow"
                :href="`tel:${currentData.store.cs_phone_number}`"
                class="noline text-e5c"
                >{{ currentData.store.cs_phone_number }}</a
              >
            </div> -->
          </div>
          <div v-if="type == 'op'" class="px-4 pb-5">
            <h3 class="mb-4 px-3 fw-600 text-center ff-prata">
              {{ $t("will_call_you") }}
            </h3>
            <!-- https://cdn-cf2.lovestruck.com/upload-m/gen/img/ls_com_black/calling_desktop_uk.png -->
            <div v-if="callImg" class="my-4">
              <b-img class="w-100" :src="callImg"></b-img>
            </div>
            <p class="mb-4">
              {{ $t("will_call_you_desc") }}
            </p>
            <p v-if="currentData">
              {{ $t("our_team_call_you") }}
              <b class="text-ee1">{{ currentData.client.phone_number }}</b>
            </p>
            <p class="mb-4">{{ $t("usually_48_hours") }}</p>
            <p v-html="$t('any_q_contact')"></p>

            <div v-if="currentData" class="fw-600">
              <a
                rel="nofollow"
                :href="`tel:${currentData.store.cs_phone_number}`"
                class="mr-1"
              >
                <img
                  src="https://cdn-cf2.lovestruck.com/upload-m/gen/img/ls_com_black/call_icon_white.png"
                  height="20"
                />
              </a>
              <a rel="nofollow" href="javascript:;" class="mr-1">
                <img
                  src="https://cdn-cf2.lovestruck.com/upload-m/gen/img/ls_com_black/whatsapp_icon_white.png.png"
                  height="20"
                />
              </a>
              <a
                rel="nofollow"
                :href="`tel:${currentData.store.cs_phone_number}`"
                class="noline text-e5c"
                >{{ currentData.store.cs_phone_number }}</a
              >
            </div>
          </div>
        </div>
        <div class="bg-ls-fe px-3">
          <div class="py-5 px-3 text-center">
            <h2 class="mb-3 px-3 fw-600 text-36 ff-prata">
              {{ $t("team_excit_meet_you") }}
            </h2>
            <p class="mb-5 text-6e">{{ $t("meet_team_desc") }}</p>

            <div v-if="matchmakers" class="row">
              <div
                v-for="(item, index) in matchmakers"
                :key="index"
                class="col-md-6 ls-col-sm-6 mb-4"
              >
                <div class="px-3">
                  <b-img class="w-100" :src="item.photo_url"></b-img>
                  <div class="mt-3">
                    <p class="text-ls-y4 fs-18 fw-600 mb-2">{{ item.name }}</p>
                    <p class="text-6e">{{ item.job_title }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="bg-ls-f2 px-3">
          <!-- what_client_say -->
          <div class="py-5 px-3 text-center">
            <h2 class="mb-3 px-3 fw-600 text-36 ff-prata">
              {{ $t("what_client_say") }}
            </h2>
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12 mb-4">
                <div class="googleReviewItemV10">
                  <img
                    :src="`${cdnBase}ls.com/${cdnDir}/review1.jpg?aa`"
                    class="w-100"
                  />
                </div>
              </div>
              <div class="col-lg-12 col-md-12 col-sm-12 mb-4">
                <div class="googleReviewItemV10">
                  <img
                    :src="`${cdnBase}ls.com/${cdnDir}/review2.jpg?aa`"
                    class="w-100"
                  />
                </div>
              </div>
              <div class="col-lg-12 col-md-12 col-sm-12 mb-4">
                <div class="googleReviewItemV10">
                  <img
                    :src="`${cdnBase}ls.com/${cdnDir}/review3.jpg?aa`"
                    class="w-100"
                  />
                </div>
              </div>
              <div class="col-lg-12 col-md-12 col-sm-12 mb-4">
                <div class="googleReviewItemV10">
                  <img
                    :src="`${cdnBase}ls.com/${cdnDir}/review4.jpg?aa`"
                    class="w-100"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="bg-ls-fe px-3">
          <!-- success stories -->
          <div v-if="successStories" class="py-5 px-3 bg-white">
            <h2 class="mb-4 px-3 fw-600 text-36 ff-prata">
              {{ $t("success_story") }}
            </h2>

            <div
              v-for="(item, index) in successStories"
              :key="index"
              class="successStoryItemV10 mb-4"
            >
              <div>
                <div class="px-3 position-relative">
                  <span class="position-absolute" style="left: 1rem; top: 10px"
                    ><i class="lsicon story_quote_icon_gold lsicon-36"></i>
                  </span>
                  <div class="px-3 pt-3 position-relative">
                    <p class="fs-18 fw-600 text-left ml-3">{{ item.head }}</p>
                    <div class="mb-3 text-center">
                      <img :src="item.photo_url" width="220" />
                    </div>
                    <p class="">
                      {{ item.desc }}
                      <span class="position-absolute" style="bottom: -12px"
                        ><i class="lsicon story_quote_icon_gold lsicon-36"></i>
                      </span>
                    </p>
                  </div>

                  <p class="fw-600 text-right pr-4 text-ls-y4">
                    {{ item.names }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//has_diamond=1 and membership_level_id<=1 and is_verified=0
import topName from "../common/topNav";
export default {
  name: "matchmaking-callback",
  components: {
    topName,
  },
  data() {
    return {
      type: "cb",
      // userInfo: this.$storage.get("userInfo"),
      cdnBase: "https://cdn-cf2.lovestruck.com/upload-m/gen/img/",
      currentData: null,
      matchmakers: null,
      successStories: null,
      cdnDir: "hongkong",
      curSite: this.$store.getters.curSite,
      hiText: "",
      callYouOn: "",
      callImg: null,
    };
  },
  mounted() {
    let self = this;
    // console.log(this.userInfo)

    if (self.curSite.iso2 == "SG") {
      // self.topImageName = 'sg'
    } else if (self.curSite.iso2 == "TH") {
      // self.topImageName = 'bkk'
      self.cdnDir = "bangkok";
    }

    self.$api
      .getClientMe(self.$store.state)
      .then((rs) => {
        self.currentData = rs;

        if (_.get(self.currentData, "client.city.city_url") == "london") {
          self.callImg =
            "https://cdn-cf2.lovestruck.com/upload-m/gen/img/ls_com_black/calling_desktop_uk.png";
        } else {
          self.callImg =
            "https://cdn-cf2.lovestruck.com/upload-m/gen/img/ls-app-6.0/call_back.jpg";
        }

        if (
          _.get(self.currentData, "client.membership_level_id") <= 1 &&
          _.get(self.currentData, "client.total_matchmaker_msg") < 10
        ) {
          self.$api.getSendFirstMatchmakerMsg("", self.$store.state);
        }
        // console.log('currentData', self.currentData)
        if (_.get(self.currentData, "client.membership_has_expired") == 1) {
          self.$router.push({ name: "renew-payment" });
          return false;
        } else {
          self.hiText = self
            .$t("hi_someone")
            .replace("[username]", self.currentData.client.first_name);
          self.callYouOn = self
            .$t("will_call_you_on")
            .replace(
              "[phone_mobile]",
              `<b class="text-ee1">${self.currentData.client.phone_number}</b>`
            );
          return self.$api.getPageData("", self.$store.state);
        }
      })
      .then((rs) => {
        // console.log('getSuccessStories', rs)
        self.successStories = _.get(rs, "successStories");
        self.matchmakers = _.get(rs, "matchmakers");
        // self.top_image_url = _.get(rs, 'top_image_url')
      });
  },
};
</script>